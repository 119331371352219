import { FormControl, IconButton, makeStyles, Switch, TableCell, TableRow } from '@material-ui/core';
import clsx from 'clsx';
import { KeyboardDateTimePicker, KeyboardTimePicker } from '@material-ui/pickers';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { formatTime, parseDateTime } from './tools';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  disabledRow: {
    backgroundColor: theme.palette.action.hover,
  }
}));


export default function OfficeTimingRow(props) {
  const classes = useStyles();

  const enable = enabled => props.onChange({...props, enabled});
  const setAllDay = all_day => props.onChange({...props, all_day});
  const setStartTime = time => {
    if (props.end_time) {
      const end_time = parseDateTime(props.end_time);
      if (time >= end_time) {
        end_time.setMinutes(end_time.getMinutes() - 1);
        time = end_time;
      }
    }
    props.onChange({
      ...props, start_time: formatTime(time)
    });
  };
  const setEndTime = time => {
    if (props.start_time) {
      const start_time = parseDateTime(props.start_time);
      if (time <= start_time) {
        start_time.setMinutes(start_time.getMinutes() + 1);
        time = start_time;
      }
    }
    props.onChange({
      ...props, end_time: formatTime(time)
    });
  };

  const anyDay = props.day === null;

  return (
    <TableRow className={clsx(!props.enabled && classes.disabledRow)}>
      <TableCell padding="checkbox">
        <FormControl margin="dense">
          <Switch checked={props.enabled}
                  onChange={e => enable(e.target.checked)} color="primary" />
        </FormControl>
      </TableCell>
      <TableCell>
        {!anyDay && props.day_display}
      </TableCell>
      <TableCell padding="checkbox">
        {!anyDay && <FormControl margin="dense">
          <Switch checked={props.all_day} disabled={!props.enabled}
                  onChange={e => setAllDay(e.target.checked)}/>
        </FormControl>}
      </TableCell>
      <TableCell>
        {anyDay ? <KeyboardDateTimePicker
          margin="dense"
          ampm={true}
          value={parseDateTime(props.start_time)}
          onChange={setStartTime}
          showTodayButton
          todayLabel="now"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        /> : <KeyboardTimePicker
          margin="dense"
          disabled={props.all_day || !props.enabled}
          ampm={true}
          value={parseDateTime(props.start_time)}
          onChange={setStartTime}
          showTodayButton
          todayLabel="now"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />}
      </TableCell>
      <TableCell>
        {anyDay ? <KeyboardDateTimePicker
          margin="dense"
          ampm={true}
          value={parseDateTime(props.end_time)}
          onChange={setEndTime}
          showTodayButton
          todayLabel="now"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        /> : <KeyboardTimePicker
          margin="dense"
          disabled={props.all_day || !props.enabled}
          ampm={true}
          value={parseDateTime(props.end_time)}
          onChange={setEndTime}
          showTodayButton
          todayLabel="now"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />}
      </TableCell>
      <TableCell>
        <IconButton
          style={{padding: '8px'}}
          color="secondary"
          onClick={() => props.onDelete(props.id)}
        ><DeleteIcon/></IconButton>
      </TableCell>
    </TableRow>
  );
}
