import React from 'react';
import {addDays, format, startOfWeek} from "date-fns";
import { Paper, Box} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  day: {
    textAlign: "center",
    width: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 60,
    fontWeight: 600
  },
});

export default function Days(props){
  const dateFormat = 'EEE';
  const classes = useStyles();
  const startDate = startOfWeek(props.currentMonth);

  const getDays = () => {
    const days = [];
    for(let i = 0; i < 7; i++){
      days.push(
          <Paper variant="outlined" className={classes.day}>
            {format(addDays(startDate, i), dateFormat)}
          </Paper>
      );
    }
    return days;
  };

  return(
    <Box
      display="flex"
      flexDirection="row"
      p={1}
      m={1}
    >
      {getDays()}
    </Box>
  );
}