export const handleResponse = (response, setSuccessAlert, setOpenSnackbar) => {
    const respStatus = response.status;
    if (respStatus === 200){
        setSuccessAlert(true);
    } else if(respStatus === 400){
        setSuccessAlert(false);
    }
    setOpenSnackbar(true);
    return response.json();
};
