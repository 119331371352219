import {useAPI} from "../../common/API";
import React, {useEffect, useState} from "react";
import {Divider, makeStyles, Paper} from "@material-ui/core";
import {HolidayQuestionBox} from "./HolidayComponents/HolidayQuestionBox";
import HolidayStart from "./HolidayComponents/HolidayStart";
import HolidayEnd from "./HolidayComponents/HolidayEnd";
import Button from "@material-ui/core/Button";


const useStyles = makeStyles(theme => ({
    submitButton: {
        paddingTop: theme.spacing(1),
        padding: theme.spacing(2),
        textAlign: "center",
    }
}));


export const FederalHoliday = (props) => {
    const api = useAPI();
    const classes = useStyles();
    const [enableHoliday, setEnableHoliday] = useState(props.holiday.enable);

    const defaultHolidayRule = {
        holiday: props.holiday && props.holiday.id,
        fixed_start_time: '17:00',
        days_before: 0,
        fixed_end_time: '23:59',
        minutes_before: null,
        minutes_after: null,
        holiday_weekday: null,
        respond_weekday: null,
    };

    const getHolidayRespondRules = () => {
        return props.holiday.respond_rules.length !== 0 ? props.holiday.respond_rules : [defaultHolidayRule];
    };
    const [holidayRulesList, setHolidayRulesList] = useState(() => getHolidayRespondRules(props.holiday));

    const setUpdatedHolidaysData = (res) => {
        const holidaysList = [...props.allHolidays];
        const index = holidaysList.indexOf(props.allHolidays.filter(holiday => holiday.id === res.id)[0]);
        props.allHolidays[index] = res;
    };

    const updateHoliday = (data, holidayId, setHolidayRulesList=null) => {
        api.patch(`api/v1/holidays/${holidayId}`, {
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(res => props.handleResponse(res))
            .then(res => {
                props.setRespData(res);
                setHolidayRulesList && setHolidayRulesList(res.respond_rules);
                setUpdatedHolidaysData(res);
            });
    };

    useEffect(() => {
        setHolidayRulesList(getHolidayRespondRules());
    }, [props.holiday]);

    const handleEnableClick = (value) => {
        setEnableHoliday(value);
        updateHoliday({enable: value}, props.holiday.id);
    };

    return (
        <Paper>
            <HolidayQuestionBox
                holiday={props.holiday}
                onClick={handleEnableClick}
                holidayName={props.holiday.holiday_name}
                updateHoliday={updateHoliday}
                settingsComponent={props.settingsComponent}
            />
            {Boolean(enableHoliday) && <div>
                <Divider/>
                <HolidayStart
                    holiday={props.holiday}
                    daysBefore={1}
                    setHolidayRules={setHolidayRulesList}
                    holidayRulesList={holidayRulesList}
                    settingsComponent={props.settingsComponent}
                />
                <Divider/>
                <HolidayEnd
                    holiday={props.holiday}
                    setHolidayRules={setHolidayRulesList}
                    federal={true}
                    settingsComponent={props.settingsComponent}
                    holidayRulesList={holidayRulesList}
                />
                <div  className={classes.submitButton}>
                    <Button
                        onClick={() => updateHoliday({"respond_rules": holidayRulesList, "enable": enableHoliday}, props.holiday.id, setHolidayRulesList)}
                        color="primary"
                        variant="contained"
                    >
                        Submit settings
                    </Button>
                </div>
            </div>
            }
        </Paper>
    );
};
