import React, {useEffect, useState} from "react";
import {
	Button, Collapse,
	Divider,
	makeStyles,
	Paper
} from "@material-ui/core";
import {AnotherHolidayQuestionBox, HolidayQuestionBox} from "./HolidayComponents/HolidayQuestionBox";
import HolidayStart from "./HolidayComponents/HolidayStart";
import HolidayWeekdayRules from "./HolidayComponents/WeekdayRules/HolidayWeekdayRules";
import HolidayEnd from "./HolidayComponents/HolidayEnd";
import {useAPI} from "../../common/API";
import {STEPS} from "./WizardStepper";
import CustomEditor from "../Settings/TemlateEditor/Editor";
import {QuestionBoxContent} from "./utils/QuestionBox";


const useStyles = makeStyles(theme => ({
	submitButton: {
		padding: theme.spacing(2),
		textAlign: "center",
	},
	paperQuestionBox: {
		marginTop: theme.spacing(3),
	},
	cholEndSection: {
		marginTop: theme.spacing(3),
	},
	settingsRoot: {
		padding: theme.spacing(3),
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
		flexGrow: 1,
	},
	root: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(3),
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
		flexGrow: 1
	},
	template: {
		paddingTop: theme.spacing(2)
	}
}));


const RulesSettings = (props) => {
	const classes = useStyles();
	const defaultHolidayRule = () => {
		let startTime = props.holiday.holiday_name === STEPS[5] ? '16:00' : '12:00';
		let endTime = '23:59';
		let minBefore = null;
		let minAfter = null;
		let daysBefore = props.holiday.assets.first_choice_value;
		const excludeHolidays = [STEPS[4], STEPS[7]];

		if (props.roshHashanaRespondRules && !excludeHolidays.includes(props.holiday.holiday_name)) {
			if (props.roshHashanaRespondRules.fixed_start_time) {
				startTime = props.roshHashanaRespondRules.fixed_start_time;
			} else {
				startTime = null;
				minBefore = props.roshHashanaRespondRules.minutes_before;
			}

			if (props.roshHashanaRespondRules.fixed_end_time) {
				endTime = props.roshHashanaRespondRules.fixed_end_time;
			} else {
				endTime = null;
				minAfter = props.roshHashanaRespondRules.minutes_after;
			}
			daysBefore = props.roshHashanaRespondRules.days_before;
		}

		if (props.holiday.holiday_name === STEPS[2]){
			daysBefore = props.holiday.assets.second_choice_value;
			startTime = '16:00';
			minBefore = null;
		}

		if (props.holiday.holiday_name === STEPS[5]){
			daysBefore = props.holiday.assets.first_choice_value;
			startTime = '16:00';
			minBefore = null;
		}


		return {
			holiday: props.holiday && props.holiday.id,
			fixed_start_time: startTime,
			days_before: daysBefore,
			fixed_end_time: endTime,
			minutes_before: minBefore,
			minutes_after: minAfter,
			holiday_weekday: null,
			respond_weekday: null,
		};
	};

	const getHolidayRulesList = () => {
		return props.holiday.respond_rules.length >= 1 ? props.holiday.respond_rules : [defaultHolidayRule()];
	};

	const [holidayRulesList, setHolidayRulesList] = useState(() => getHolidayRulesList());

	useEffect(() => {
		setHolidayRulesList(getHolidayRulesList());
	}, [props.holiday]);

	useEffect(() => {
		props.setHolidaySettings({"respond_rules": holidayRulesList});
	}, [holidayRulesList]);

	return (
		<div>
			{props.settingsComponent ? (
				<HolidayStart
					holiday={props.holiday}
					defaultMinBefore={props.defaultMinStart}
					setDefaultMinutesBefore={props.setDefaultMinStart}
					daysBeforeMapping={props.daysBeforeMapping}
					setHolidayRules={setHolidayRulesList}
					holidayRulesList={holidayRulesList}
					roshHashanaRespondRules={props.roshHashanaRespondRules}
					settingsComponent={props.settingsComponent}
				/>
			) : (
				<Paper>
					<HolidayStart
						holiday={props.holiday}
						defaultMinBefore={props.defaultMinStart}
						setDefaultMinutesBefore={props.setDefaultMinStart}
						daysBeforeMapping={props.daysBeforeMapping}
						setHolidayRules={setHolidayRulesList}
						holidayRulesList={holidayRulesList}
						roshHashanaRespondRules={props.roshHashanaRespondRules}
					/>
				</Paper>
			)}
			<Divider/>
			<HolidayWeekdayRules
				settingsComponent={props.settingsComponent}
				holiday={props.holiday}
				holidayRulesList={holidayRulesList}
				setHolidayRulesList={setHolidayRulesList}
				croppedName={props.holiday.event_type === 'CE' && props.assets.common_name}
			/>
			<Divider/>
			{props.settingsComponent ? (
				<React.Fragment>
					<HolidayEnd
						settingsComponent={props.settingsComponent}
						holiday={props.holiday}
						endingDateText={props.assets.ending_holiday_text}
						setHolidayRules={setHolidayRulesList}
						holidayRulesList={holidayRulesList}
						roshHashanaRespondRules={props.roshHashanaRespondRules}
					/>
					<Divider />
				</React.Fragment>
				) : (
				<Paper>
					<HolidayEnd
						holiday={props.holiday}
						endingDateText={props.assets.ending_holiday_text}
						setHolidayRules={setHolidayRulesList}
						holidayRulesList={holidayRulesList}
						roshHashanaRespondRules={props.roshHashanaRespondRules}
					/>
				</Paper>
			)}
			{props.settingsComponent && <div className={classes.submitButton}>
				<Button
					onClick={() => props.updateHoliday({
						"respond_rules": holidayRulesList
					}, props.holiday.id, setHolidayRulesList)}
					color="primary"
					variant="contained"
				>
					Submit settings
				</Button>
			</div>}
		</div>
	);
};


export const WizardStepContent = (props) => {
	const api = useAPI();
	const classes = useStyles();

	const getHolidayByType = (holidays, type) => {
		let holiday = null;
		if (holidays.length !== 1) {
			holiday = holidays.filter(holiday => holiday.event_type === type)[0];
		} else if (type === 'CS' || type === 'U') {
			holiday = holidays[0];
		}
		return holiday;
	};

	const holiday = getHolidayByType(props.holidays, 'CS');
	const cholHamoed = getHolidayByType(props.holidays, 'CB');
	const cholHamoedEnd = getHolidayByType(props.holidays, 'CE');

	const [holidayEnabled, setHolidayEnabled] = useState(holiday && holiday.enable);
	const [cholHamoedEnabled, setCholHamoedEnabled] = useState(cholHamoed && cholHamoed.enable);
	const [endCholHamoedEnabled, setEndCholHamoedEnabled] = useState(cholHamoedEnd && cholHamoedEnd.enable);

	const enableHoliday = (value) => {
		setHolidayEnabled(value);
		updateHoliday({enable: value}, holiday.id);
		if (cholHamoedEnd) {
			updateHoliday({enable: value}, cholHamoedEnd.id);
		}
	};

	const enableCholHamoedHoliday = (value) => {
		setCholHamoedEnabled(value);
		updateHoliday({enable: value}, cholHamoed.id);
	};

	const [holidaySettings, setHolidaySettings] = useState({});
	const [cholHamoedSettings, setCholHamoedSettings] = useState({});
	const [checked, setChecked] = useState(holiday ? holiday.enable_template_draft : false);
	const handleTemplateEnable = (value) => {
			api.patch(`api/v1/holidays/${holiday.id}`, {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({"enable_template_draft": value})
		})
			.then(res => props.handleResponse(res));
		setChecked(value);
	};
	const updateTemplate = (template) => {
		api.patch(`api/v1/holidays/${holiday.id}`, {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({"template_message": template})
		})
			.then(res => props.handleResponse(res));
	};

	useEffect(() => {
		props.setHolidaysSettings && props.setHolidaysSettings([
			{holidayId: holiday.id, data: {enable: holidayEnabled, ...holidaySettings}},
			cholHamoed && {holidayId: cholHamoed.id, data: {enable: cholHamoedEnabled, ...cholHamoedSettings}},
			cholHamoedEnd && {holidayId: cholHamoedEnd.id, data: {enable: endCholHamoedEnabled}},
		]);
	}, [holidaySettings, holidayEnabled, cholHamoedSettings, cholHamoedEnabled, endCholHamoedEnabled]);

	const enableHolidaysBatch = () => {
		const possibleTypes = ['U', 'CB', 'CS', 'CE'];
		const holidays = props.allHolidays.filter(
			holiday => ![STEPS[4], STEPS[7]].includes(holiday.holiday_name) && possibleTypes.includes(holiday.event_type)
		);
		const data = [];
		for (let i = 0; i < holidays.length; i++) {
			data.push({"id": holidays[i].id, "enable": true});
		}

		api.patch("api/v1/holidays-list-update", {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data)
		})
			.then(res => props.handleResponse(res))
			.then(res => {
				res.map(holiday => {
					props.setUpdatedHolidaysData(holiday);
				});
			});
	};

	const updateHoliday = (data, holidayId, setHolidayRulesList = null) => {
		api.patch(`api/v1/holidays/${holidayId}`, {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data)
		})
			.then(res => props.handleResponse(res))
			.then(res => {
				props.setRespData(res);
				if (!data.another_holiday) {
					setHolidayRulesList && setHolidayRulesList(res.respond_rules);
				}
				props.setUpdatedHolidaysData(res, data);
			});
	};

	const updateAnotherHoliday = (settings, holidayType, enable) => {
		const holidayId = props.allHolidays.find(hol =>
			hol.holiday_name === holiday.assets.another_holiday_name && hol.event_type === holidayType
		).id;
		const data = {
			enable: enable,
			...settings,
			"another_holiday": true
		};

		holidayId && api.patch(`api/v1/holidays/${holidayId}`, {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data)
		})
			.then(res => props.handleResponse(res))
			.then(res => props.setUpdatedHolidaysData(res, data));

		props.completeSteps && props.completeSteps(props.stepsNames.indexOf(props.anotherHoliday));
	};

	const handleSettingsOnAnotherHoliday = (value) => {
		if (value) {
			let smallHolidaySettings = JSON.parse(JSON.stringify(holidaySettings));
			let bigHolidaySettings = JSON.parse(JSON.stringify(cholHamoedSettings));

			if(holiday.assets.another_holiday_name === STEPS[5]){
				let defaultSmallHolidayRule = smallHolidaySettings.respond_rules[0];
				let defaultBigHolidayRule = bigHolidaySettings.respond_rules[0];
				const smallDaysBefore = props.allHolidays.filter(
					hol => hol.holiday_name === STEPS[5] && hol.event_type === 'CS'
				)[0].assets.first_choice_value;
				const bigDaysBefore = props.allHolidays.filter(
					hol => hol.holiday_name === STEPS[5] && hol.event_type === 'CB'
				)[0].assets.first_choice_value;
				defaultSmallHolidayRule.fixed_start_time = '16:00';
				defaultBigHolidayRule.fixed_start_time = '16:00';
				defaultSmallHolidayRule.minutes_before = null;
				defaultBigHolidayRule.minutes_before = null;
				defaultSmallHolidayRule.days_before = smallDaysBefore;
				defaultBigHolidayRule.days_before = bigDaysBefore;
			}
			typeof holidayEnabled === 'boolean' && updateAnotherHoliday(smallHolidaySettings, holiday.event_type, holidayEnabled); // holiday type could be 'U' / 'CS'
			typeof cholHamoedEnabled === 'boolean' && updateAnotherHoliday(bigHolidaySettings, 'CB', cholHamoedEnabled);
		}
	};

	const handleHoliday = (value) => {
		setHolidayEnabled(value);
		setEndCholHamoedEnabled(value);
		props.completeSteps && props.completeSteps(props.step);
	};

	const handleCholHamoed = (value) => {
		setCholHamoedEnabled(value);
		props.completeSteps && props.completeSteps(props.step);
	};

	return (
		<div>
			{props.settingsComponent ? (
				<HolidayQuestionBox
					holiday={holiday}
					cholHamoed={cholHamoed}
					onClick={enableHoliday}
					handleCholHamoed={enableCholHamoedHoliday}
					enableHolidaysBatch={enableHolidaysBatch}
				/>
			) : (
				<Paper className={classes.paperQuestionBox}>
					<HolidayQuestionBox
						holiday={holiday}
						cholHamoed={cholHamoed}
						onClick={handleHoliday}
						handleCholHamoed={handleCholHamoed}
						enableHolidaysBatch={enableHolidaysBatch}
					/>
				</Paper>
			)}
			<Divider/>
			{(holidayEnabled || cholHamoedEnabled) && <div>
				{/* section for chol hamoed big holiday*/}
				{cholHamoedEnabled && <RulesSettings
					holiday={cholHamoed}
					defaultMinStart={props.defaultMinStart}
					defaultMinEnd={props.defaultMinEnd}
					setDefaultMinStart={props.setDefaultMinStart}
					assets={cholHamoed.assets}
					updateHoliday={updateHoliday}
					settingsComponent={props.settingsComponent}
					setHolidaySettings={setCholHamoedSettings}
					roshHashanaRespondRules={props.roshHashanaRespondRules}
				/>}
				{/* section for chol hamoed small or usual holiday*/}
				{holidayEnabled && !cholHamoedEnabled && <RulesSettings
					holiday={holiday}
					defaultMinStart={props.defaultMinStart}
					defaultMinEnd={props.defaultMinEnd}
					setDefaultMinStart={props.setDefaultMinStart}
					assets={holiday && holiday.assets}
					updateHoliday={updateHoliday}
					settingsComponent={props.settingsComponent}
					setHolidaySettings={setHolidaySettings}
					roshHashanaRespondRules={props.roshHashanaRespondRules}
				/>
				}
				{/* section for creating same settings for another holiday*/}
				{!props.settingsComponent && holiday.assets.another_holiday_name && <Paper className={classes.paperQuestionBox}>
					<AnotherHolidayQuestionBox
						assets={holiday.assets}
						onClick={handleSettingsOnAnotherHoliday}
						enabledAnotherHoliday={props.allHolidays.filter(
							hol => hol.holiday_name === holiday.assets.another_holiday_name
						).some(holiday => holiday.is_duplicate === true)}
					/>
				</Paper>}
				{props.settingsComponent && <Divider/>}
				<Paper className={props.settingsComponent ? classes.settingsRoot : classes.root}>
					<QuestionBoxContent
						enabled={checked}
						questionText={`Enable holiday response template for ${holiday.holiday_name}`}
						handleClick={handleTemplateEnable}
					/>
					<Collapse in={checked}>
						<div className={classes.template}>
							<CustomEditor
								templateMessage={holiday? holiday.template_message : ""}
								onChange={updateTemplate}
								buttonPosition="center"
								buttonText="Submit Template"
								buttonSize="normal"
							/>
						</div>
					</Collapse>
				</Paper>
			</div>
			}
		</div>
	);
};
