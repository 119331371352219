function getPayloadFromOption(option) {
    return {
        1: {
            reply_on_emails: true,
            office_timing_enabled: true,
            holidays_enabled: true,
            reply_always: true,
        },
        2: {
            reply_on_emails: true,
            office_timing_enabled: true,
            holidays_enabled: false,
            reply_always: false,
        },
        3: {
            reply_on_emails: true,
            office_timing_enabled: true,
            holidays_enabled: true,
            reply_always: false,
        },
        4: {
            reply_on_emails: true,
            office_timing_enabled: false,
            holidays_enabled: true,
            reply_always: false,
        },
        5: {
            reply_on_emails: false,
            office_timing_enabled: false,
            holidays_enabled: false,
            reply_always: false,
        },
    }[option];
}

function mapValuesToOption(values) {
    if (values.replyAlways) {
        return 1;
    }
    if (values.autoReply) {
        if (values.officeTimingReply && !values.holidaysReply) {
            return 2;
        }
        if (!values.officeTimingReply && values.holidaysReply) {
            return 4;
        }
        return 3;
    }
    return 5;
}

export { getPayloadFromOption, mapValuesToOption };
