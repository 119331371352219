import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Copyright from '../common/Copyright';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useAuth} from "../common/auth";
import {Link, useHistory} from "react-router-dom";
import {Grid} from "@material-ui/core";
import UserMenu from "./UserMenu";


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  hijackToolbar: {
    paddingRight: 24,
    background: "red",
  },
  hijackButton: {
    background: "white",
    color: "red",
    marginLeft: 10,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  logoutBtn: {
    marginLeft: 10,
  },
  links: {
    color: "white",
    textDecoration: 'none',
  },
  settings: {
    marginRight: 20,
  },
  paperModal: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit / 2,
    top: theme.spacing.unit / 2,
    color: theme.palette.grey[500],
  },
}));


export default function AuthorizedWrapper(props) {
  const classes = useStyles();
  const authUser = useAuth(),
    history = useHistory();

  const releaseUser = resp => authUser.signOut(resp).then(() => {
    localStorage.setItem('hijackToken', null);
    localStorage.setItem('hijackUserObj', null);
    localStorage.setItem('hijackUser', 'false');
    history.push('/admin/core/user/');
  });

  const hijacked = localStorage.getItem("hijackUser");

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={hijacked === 'true'? classes.hijackToolbar : classes.toolbar}>
          <Grid container justify="space-between" direction="row" alignItems="center">
            <Grid item justify="flex-start" xs={2} lg={7}>
              <Link to="/" className={classes.links}>
                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                  Auto Responder
                </Typography>
              </Link>
            </Grid>
            <Grid container item justify="flex-end" direction="row" alignItems="center" xs={10} lg={5}>
              <Grid>
              <Link to="/" className={classes.links}>
                <Typography component="h2" variant="subtitle1" color="inherit" noWrap className={classes.settings}>
                  Settings
                </Typography>
              </Link>
            </Grid>
              <Grid>
              <Link to="/logs" className={classes.links}>
                <Typography component="h2" variant="subtitle1" color="inherit" noWrap className={classes.settings}>
                  Logs
                </Typography>
              </Link>
              </Grid>
              <Grid>
                <Link to="/wizard" className={classes.links}>
                  <Typography component="h2" variant="subtitle1" color="inherit" noWrap className={classes.settings}>
                    Wizard
                  </Typography>
                </Link>
              </Grid>
              <Grid>
                <Link to="/events" className={classes.links}>
                  <Typography component="h2" variant="subtitle1" color="inherit" noWrap className={classes.settings}>
                    Events List
                  </Typography>
                </Link>
              </Grid>
              <Grid>
                <Typography component="h5" color="inherit" noWrap>
                  {authUser.user.email}
                </Typography>
              </Grid>
              <Grid>
                <UserMenu hijacked={hijacked} releaseUser={releaseUser}/>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {props.children}
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
