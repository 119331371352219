import React, {useEffect, useState} from "react";
import {Grid, Toolbar, Typography, Paper, Collapse, makeStyles, Snackbar, Divider} from "@material-ui/core";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@material-ui/icons/ArrowDropUpOutlined";
import {FederalHoliday} from "./FederalHoliday";
import {Alert} from "@material-ui/lab";
import {parseErrors} from "../../utils/ parseErrors";
import {WizardStepContent} from "./WizardStepContent";
import HebcalSettings from "../Settings/HebcalSettings";
import {QuestionBox} from "./utils/QuestionBox";


const useStyles = makeStyles(theme => ({
    settingsBox: {
      marginBottom: theme.spacing(3),
    },
    root: {
        transition: theme.transitions.create('background-color', {
            duration: '300ms',
        }),
        marginBottom: theme.spacing(3)
    },
    header: {
        cursor: 'pointer',
    },
    bottomAddPanel: {
        width: 'auto',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    toolbar: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        padding: theme.spacing(2),
    },
    holidayError: {
        textAlign: "center",
        margin: theme.spacing(3),
    },
    gridTitle:  {
        marginTop: theme.spacing(1),
    }

}));


const getHebcalHoliday = (props) => {
    return <WizardStepContent
        allHolidays={props.allHolidays}
        holidays={props.holidays}
        defaultMinStart={props.defaultMinStart}
        setDefaultMinStart={props.setDefaultMinStart}
        setHolidaysData={props.setHolidaysData}
        handleResponse={props.handleResponse}
        setRespData={props.setRespData}
        settingsComponent={true}
        setUpdatedHolidaysData={props.setUpdatedHolidaysData}
    />;
};


const getFederalHoliday = (props) => {
    return <FederalHoliday
        settingsComponent={true}
        holiday={props.holiday}
        handleResponse={props.handleResponse}
        setRespData={props.setRespData}
        allHolidays={props.allHolidays}
    />;
};


const HolidayEvent = (props) => {
    const classes = useStyles();
    const [openSettings, setOpenSettings] = useState(false);

    const handleOpen = () => {
        setOpenSettings(!openSettings);
    };

    return(
        <Paper className={classes.root}>
            <Toolbar className={classes.toolbar} onClick={handleOpen}>
                <Grid className={classes.header} container direction="row"
                      justify="space-between" component="label">
                    <Grid item className={classes.gridTitle}>
                        <Typography>{props.holiday ? props.holiday.holiday_name : props.name}</Typography>
                    </Grid>
                    <Grid item>
                        {!openSettings ? (
                            <ArrowDropDownOutlinedIcon fontVariant="outlined" color="primary" fontSize="large" onClick={handleOpen} />
                        ) : (
                            <ArrowDropUpOutlinedIcon fontVariant="outlined" color="primary" fontSize="large" onClick={handleOpen} />
                        )}
                    </Grid>
                </Grid>
            </Toolbar>
            <Collapse in={openSettings}>
                <Divider />
                {!props.holiday && props.holidays && props.holidays.length < 1 ? (<div className={classes.holidayError}>
                        <Typography component="span" variant="subtitle1" id="simple-modal-description">
                            Be sure you have completed fields on <b>Calendar settings</b> step.
                        </Typography>
                    </div>
                    ): (props.getHoliday(props))
                }
            </Collapse>
        </Paper>
    );
};

export const WizardSettings = (props) => {
    const classes = useStyles();
    const federalHolidays = props.holidays.filter(holiday => holiday.uploaded_from === "FED").sort(
        (a, b) => new Date(b.start_date) - new Date(a.start_date)
    ).reverse();

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [successAlert, setSuccessAlert] = useState();
    const [errors, setErrors] = useState();
    const [respData, setRespData] = useState();
    const [defaultMinStart, setDefaultMinStart] = React.useState(
        props.settings && props.settings.hebcal_settings.candle_minutes
    );

    const handleResponse = (response) => {
        const respStatus = response.status;
        if (respStatus === 200){
            setSuccessAlert(true);
        } else if(respStatus === 400){
            setSuccessAlert(false);
        }
        setOpenSnackbar(true);
        return response.json();
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
        setErrors(null);
    };

    useEffect(() => {
        if (respData && !successAlert) {
            setErrors(Object.entries(respData));
        }
    }, [respData]);

    const hebcalHolidaysNames = [
        'Rosh Hashana / ראש השנה',
        'Yom Kippur / יום כפור',
        'Sukkot / סוכות',
        'Purim / פורים',
        'Pesach / פסח',
        'Shavuot / שבועות',
        "Tish'a B'Av / תשעה באב",
    ];

    const [enableFederalHolidays, setEnableFederalHolidays] = React.useState(
        props.settings && props.settings.enable_fed_holidays
    );

    const handleFederalHolidaysAvailability = (enable) => {
        setEnableFederalHolidays(enable);
        props.updateSettings({"enable_fed_holidays": enable});
        if(enable && props.holidays.filter(
            holiday => holiday.uploaded_from === 'FED'
        ).length === 0){
            const interval = setInterval(() => props.getHolidaysData(), 2000);
            setTimeout(() => clearInterval(interval), 8000);
        }
    };

    const setUpdatedHolidaysData = (res) => {
        const holidaysList = [...props.holidays];
        const index = holidaysList.indexOf(props.holidays.filter(holiday => holiday.id === res.id)[0]);
        props.holidays[index] = res;
    };


    return(
      <div>
          <div className={classes.settingsBox}>
              <HebcalSettings
                  hebcalVacationEnabled={props.settings ? props.settings.hebcal_vacation_enabled : false}
                  hebcalSettings={props.settings.hebcal_settings}
                  onChange={props.updateSettings}
                  onHebcalSettingsChange={props.updateHebcalSettings}
                  setOpenSnackbar={setOpenSnackbar}
                  getHolidaysData={props.getHolidaysData}
              />
          </div>
          {hebcalHolidaysNames.map((name, index) => {
              const getHolidaysByName = name => {
                  return props.holidays.filter(holiday => holiday.holiday_name === name);
              };
              const holidays = getHolidaysByName(name);
              return <HolidayEvent
                  key={holidays.length >= 1 ? `${holidays[0].holiday_name}_${holidays[0].event_type}` : `${name}_${index}`}
                  holidays={holidays}
                  step={index}
                  allHolidays={props.holidays}
                  setHolidaysData={props.setHolidays}
                  handleResponse={handleResponse}
                  setRespData={setRespData}
                  defaultMinStart={defaultMinStart}
                  setDefaultMinStart={setDefaultMinStart}
                  getHoliday={getHebcalHoliday}
                  name={name}
                  setUpdatedHolidaysData={setUpdatedHolidaysData}
              />;
          })}
          <div className={classes.settingsBox}>
              <QuestionBox
                  enabled={enableFederalHolidays}
                  questionText={"Respond on Federal holidays?"}
                  handleClick={handleFederalHolidaysAvailability}
              />
          </div>
          {enableFederalHolidays && federalHolidays.map(holiday => <HolidayEvent
              key={`${holiday.id}_${holiday.event_type}`}
              holiday={holiday}
              handleResponse={handleResponse}
              setRespData={setRespData}
              allHolidays={props.holidays}
              getHoliday={getFederalHoliday}
          />)}
          {
              successAlert && (
                  <Snackbar open={openSnackbar} autoHideDuration={1000} onClose={handleClose}>
                      <Alert onClose={handleClose} severity="success" xs={{ width: '100%' }}>
                          Settings successfully updated!
                      </Alert>
                  </Snackbar>
              )
          }
          {
              !successAlert && errors && errors.length > 0 && (
                  <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleClose}>
                      <Alert onClose={handleClose} severity="error" xs={{ width: '100%' }}>
                          {parseErrors(...errors)}
                      </Alert>
                  </Snackbar>
              )
          }
      </div>
    );
};