import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow,
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import EmailRuleRow from "./EmailRuleRow";
import NewEmailRule from "./NewEmailRule";
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import DeterminateLinearProgress
  from "../../../utils/DeterminateLinearProgress";

const useEmailRuleStyles = makeStyles(theme => ({
  disabled: {
    backgroundColor: theme.palette.action.hover,
  },
  root: {
    transition: theme.transitions.create('background-color', {
      duration: '300ms',
    }),
    marginBottom: 15
  },
  header: {
    cursor: 'pointer',
  },
  bottomAddPanel: {
    width: 'auto',
  },
  editor:{
    padding: 5,
    margin: 0
  },
  saveButton: {
    padding: 5,
    margin: 0
  },
  enableSwitch: {
    marginBottom: 15,
    marginLeft: 15,
    marginRight: 15,
  },
  toolbar: {
    paddingLeft: 15,
    paddingRight: 15,
  },
}));


export default function EmailRules(props) {
  const classes = useEmailRuleStyles();
  const [openSettings, setOpenSettings] = useState(false);
  const respStatus = props.respStatus;
  const [sendingReq, setSendingReq] = useState(false);

  const update = (value, values) => {
    props.setOpenSnackbar(false);
    setSendingReq(true);
    props.onChange(value, values);
  };

  useEffect(() => {
    if(respStatus){
      setSendingReq(false);
      props.setRespStatus(null);
    }
  }, [respStatus]);

  const updateValue = value => {
    const index = props.emailRules.findIndex(i => i.id === value.id),
      values = [...props.emailRules];
    values[index] = value;
    update(
        props.emails,
        values
    );
  };
  const addValue = value => update(
      props.emails,
      [...props.emailRules, value]);
  const deleteValue = id => update(
      props.emails,
      props.emailRules.filter(item => item.id !== id)
  );

  const handleOpen = () => {
    setOpenSettings(!openSettings);
  };

  return (
    <Paper className={clsx(classes.root, !openSettings && classes.disabled)}>
      <DeterminateLinearProgress visible={sendingReq} opened={openSettings}/>
      <Toolbar className={classes.toolbar} onClick={handleOpen}>
        <Grid className={classes.header} container direction="row"
              justify="space-between" component="label">
          <Grid item>
            <Typography variant="h6" component="div">
              Email rules
            </Typography>
          </Grid>
          <Grid item>
            {!openSettings ? (
                <ArrowDropDownOutlinedIcon fontVariant="outlined" color="primary" fontSize="large" onClick={handleOpen} />
            ) : (
                <ArrowDropUpOutlinedIcon fontVariant="outlined" color="primary" fontSize="large" onClick={handleOpen} />
            )}
          </Grid>
        </Grid>
      </Toolbar>
      <Collapse in={openSettings}>
        <TableContainer>
          <Table className={classes.table} size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">Enabled</TableCell>
                <TableCell>Email inbox</TableCell>
                <TableCell>Reply from</TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.emailRules.map(item =>
                <EmailRuleRow
                  key={item.id}
                  onChange={updateValue}
                  onDelete={deleteValue}
                  {...item}
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <NewEmailRule
            addEmailRule={addValue}
            emails={props.emails}
        />
      </Collapse>
    </Paper>
  );
}
