export const parseErrors = (errors) => {
    let errorField = '';
    let errorValue = '';
    for (let i=0; i < errors.length; i++){
        if(typeof errors[i] === "string"){
            if (errorField) {
                errorField = errorField + '_' + errors[i];
            } else {
                errorField = errors[i];
            }
        } else if (Array.isArray(errors[i])) {
            errorValue = errors[i][0];
        } else {
            let obj = Object.entries(errors[i]);
            errorField = errorField + '_' + obj[0][0];
            errorValue = obj[0][1];
        }
    }

    const splitErrorName = errorField.split('_');

    for (let i = 0; i < splitErrorName.length; i++) {
        splitErrorName[i] = splitErrorName[i].charAt(0).toUpperCase() + splitErrorName[i].slice(1);
    }
    const fieldName = splitErrorName.join(" ");
    if (errorValue === '' && errors.length === 2){
            return `${errors[0].charAt(0).toUpperCase() + errors[0].slice(1)} Error:  ${errors[1]}`;
    }
    return `${fieldName} Error:  ${errorValue}`;
};