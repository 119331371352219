import React, {useEffect, useState} from 'react';
import {
    Divider,
    FormControl, IconButton,
    InputLabel, makeStyles,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow, TextField, Typography
} from "@material-ui/core";
import {days} from "../../../Settings/OfficeTimings/tools";
import DeleteIcon from '@material-ui/icons/Delete';
import {KeyboardTimePicker} from "@material-ui/pickers";


const useStyles = makeStyles(theme => ({
    table: {
        marginTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    emptyRules: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        textAlign: "center"
    }
}));


const WeekdayRuleRow = (props) => {
    const [startOption, setStartOption] = useState(props.rule.fixed_start_time ? 'fixed' : 'minutes');
    const [holidayStartDate] = useState(new Date());
    const [fixedStartTime, setFixedStartTime] = useState(
        props.rule.fixed_start_time ? props.rule.fixed_start_time.split(':') : [12, 0]
    );
    const [minBeforeSundown, setMinBeforeSundown] = useState(props.rule.minutes_before ? props.rule.minutes_before : 0);
    const [renderCounter, setRenderCounter] = useState(0);

    const handleStartOptionChange = (event) => {
        setStartOption(event.target.value);
    };

    const updateRule = (attrs) => {
        props.onChange(props.index, attrs);
    };

    const handleMinBeforeSundown = (event) => {
        let minutes = event.target.value;
        if (minutes < 0) {
            setMinBeforeSundown(0);
        } else if (minutes > 120) {
            setMinBeforeSundown(120);
        } else {
            setMinBeforeSundown(minutes);
        }
    };

    const handleDateChange = (date) => {
        setFixedStartTime([date.getHours(), date.getMinutes()]);
    };

    useEffect(() => {
        holidayStartDate.setHours(fixedStartTime[0], fixedStartTime[1]);
        if (renderCounter !== 0){
            if(startOption === 'fixed'){
                updateRule({fixed_start_time: fixedStartTime.join(':'), minutes_before: null});
            } else {
                updateRule({minutes_before: parseInt(minBeforeSundown), fixed_start_time: null});
            }
        }
        setRenderCounter(renderCounter + 1);
    }, [startOption, fixedStartTime, minBeforeSundown]);

    return (
        <TableRow>
            <TableCell>
                <FormControl style={{minWidth: '120px'}} margin="dense">
                    <InputLabel id="select-day-label">Day</InputLabel>
                    <Select
                        value={props.rule.holiday_weekday}
                        labelId="select-day-label"
                        onChange={e => updateRule({holiday_weekday: e.target.value})}
                    >
                        {days.map(item => <MenuItem
                            key={item.value}
                            value={item.value}>{item.label}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell>
                <FormControl style={{minWidth: '120px'}} margin="dense">
                    <InputLabel id="select-day-label">Day</InputLabel>
                    <Select
                        value={props.rule.respond_weekday}
                        labelId="select-day-label"
                        onChange={e => updateRule({respond_weekday: e.target.value})}
                    >
                        {days.map(item => <MenuItem
                            key={item.value}
                            value={item.value}>{item.label}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Start option</InputLabel>
                    <Select
                        value={startOption}
                        label="Start time option"
                        onChange={handleStartOptionChange}
                    >
                        <MenuItem value='fixed'>Fixed time start</MenuItem>
                        <MenuItem value='minutes'>Minutes before sundown</MenuItem>
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell>
                {startOption === 'fixed' ? (
                    <KeyboardTimePicker
                        style={{width: '100%'}}
                        label="Fixed start"
                        value={holidayStartDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                    />
                ) : (
                    <TextField
                        required
                        label= "Minutes before sundown"
                        fullWidth
                        type="number"
                        onChange={handleMinBeforeSundown}
                        value={minBeforeSundown}
                    />
                )}
            </TableCell>
            <TableCell>
                <IconButton
                    style={{padding: '8px'}}
                    color="secondary"
                    onClick={() => props.onDelete(props.rule)}
                ><DeleteIcon/></IconButton>
            </TableCell>
        </TableRow>
    );
};


export default function HolidayWeekdayRulesTable(props){
    const classes = useStyles();
    const columns = [
        { id: 'holiday_weekday', label: 'Weekday of holiday', width: '20%' },
        { id: 'respond_weekday', label: 'Start responding', width: '20%' },
        {id: 'start_option', label: 'Start option', width: '25%'},
        {id: 'time', label: 'Time', width: '25%'},
        {id: 'delete', label: '', width: '10%'},
    ];

    return(
        <TableContainer className={classes.table}>
            <Table size="small" aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {columns.map(column => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ width: column.width }}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.rules.slice(1).map((rule, index) => {
                            return <WeekdayRuleRow
                                key={rule.id ? rule.id : `${props.holiday.id}_${index}`}
                                index={index + 1}
                                rule={rule}
                                onChange={props.onChange}
                                onDelete={props.onDelete}
                            />;
                        })
                    }
                </TableBody>
            </Table>
            {props.rules.length === 0 && <div>
                <Typography className={classes.emptyRules}> There arent added rules yet</Typography>
                <Divider />
            </div>}
        </TableContainer>
    );
}