import React, { useContext } from 'react';
import { useAuth } from './auth';
import { get, post, put, patch, destroy } from './net';
import { createContext } from 'react';


export const APIContext = createContext({
  get,
  post,
  put,
  patch,
  destroy,
});


function useAPIProvider() {
  const auth = useAuth();
  return {
    get: (endpoint, params) => get(endpoint, params, auth.token),
    post: (endpoint, params) => post(endpoint, params, auth.token),
    patch: (endpoint, params) => patch(endpoint, params, auth.token),
    put: (endpoint, params) => put(endpoint, params, auth.token),
    destroy: (endpoint, params) => destroy(endpoint, params, auth.token),
  };
}


export function useAPI() {
  return useContext(APIContext);
}


export default function API({ children }) {
  const api = useAPIProvider();
  return <APIContext.Provider value={api}>
    {children}
  </APIContext.Provider>;
}
