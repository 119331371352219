import { createContext, useContext } from 'react';
import { useLocalStorage } from './utils';
import {authUser, gmailAuthorize, googleLogin} from './net';

export class Auth {
  constructor() {
    this._isAuthenticated = false;
    this.user = null;
    this.token = null;
  }

  signIn(response) {
    return new Promise(resolve => {
      this._isAuthenticated = true;
      resolve(response);
    });
  }

  signOut(response) {
    return new Promise(resolve => {
      this._isAuthenticated = false;
      resolve(response);
    });
  }

  performCheck() {}
}

export const fakeAuth = new Auth();

export const AuthContext = createContext(fakeAuth);

export const useAuth = () => {
  return useContext(AuthContext);
};

export function useProvideAuth(props) {
  const [token, setToken] = useLocalStorage(
    'token', props && props.token ? props.token : null
  );
  const [user, setUser] = useLocalStorage(
    'user', props && props.user ? props.user : null
  );
  const signIn = async resp => {
    const authToken = await googleLogin(resp.tokenObj.access_token),
      user = await authUser(authToken);
    setToken(authToken);
    setUser(user);
    await gmailAuthorize(authToken, null);
    return await fakeAuth.signIn(resp);
  };

  const signOut = resp => fakeAuth.signOut(resp).then(() => {
    setToken(null);
    setUser(null);
  });

  const check = async () => {
    const user = await authUser(token);
    setUser(user);
    if (!user.email) {
      await signOut(null);
    }
  };

  return {
    user,
    token,
    performCheck: () => {
      check().then();
    },
    signIn,
    signOut,
  };
}
