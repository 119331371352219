import { API_ENDPOINT } from 'common/variables';


export async function api(endpoint, params = {}, token = null) {
  if (token) {
    if (!params.headers) params.headers = {};
    params.headers['Authorization'] = `Token ${token}`;
  }
  return await fetch(`${API_ENDPOINT}/${endpoint}`, params);
}


export async function get(endpoint, params = {}, token = null) {
  params.method = 'GET';
  return await api(endpoint, params, token);
}


export async function post(endpoint, params = {}, token = null) {
  params.method = 'POST';
  return await api(endpoint, params, token);
}


export async function put(endpoint, params = {}, token = null) {
  params.method = 'PUT';
  return await api(endpoint, params, token);
}


export async function patch(endpoint, params = {}, token = null) {
  params.method = 'PATCH';
  return await api(endpoint, params, token);
}


export async function destroy(endpoint, params = {}, token = null) {
  params.method = 'DELETE';
  return await api(endpoint, params, token);
}


export async function authUser(token) {
  const resp = await api('api/v1/auth/user/', {
    headers: {
      'Content-Type': 'application/json',
    },
  }, token);
  return await resp.json();
}


export async function googleLogin(token) {
  const resp = await api('api/v1/auth/google/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      'access_token': token,
    }),
  });
  return (await resp.json()).key;
}


export async function gmailAuthorize(token) {
  const resp = await api('api/v1/gmail-auth/', {
    headers: {
      'Content-Type': 'application/json',
    },
  }, token);
  resp.json().then(data => {
    if (data.need_authorization) {
      window.open(data.auth_url, "_blank");
    }
  });
}