import React, {useState} from 'react';
import {
    Typography,
    makeStyles,
    Paper, Divider, Collapse, Toolbar, Grid,
} from "@material-ui/core";
import AddNewWeekdayRule from "./AddNewWeekdayRule";
import HolidayWeekdayRulesTable from "./HolidayWeekdayRulesTable";
import ArrowDropUpOutlinedIcon from "@material-ui/icons/ArrowDropUpOutlined";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";

const useStyles = makeStyles(theme => ({
    start: {
      marginTop: theme.spacing(3),
    },
    questionBox: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    selectors: {
        justifyContent: "end",
    },
    daysSelector: {
        textAlign: "center",
    },
    box: {
        marginTop: theme.spacing(3),
    },
    heading: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    divider: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    buttonContainer: {
        marginTop: theme.spacing(2),
        justifyContent: "end",
    },
    header: {
        paddingLeft: theme.spacing(1),
    }
}));


export default function HolidayWeekdayRules(props) {
    const classes = useStyles();
    const updateRule = (index, updatedValues) => {
        const rules = [...props.holidayRulesList];
        rules[index] = {...rules[index], ...updatedValues};
        props.setHolidayRulesList(rules);
    };
    const addRule = rule => props.setHolidayRulesList([...props.holidayRulesList, rule]);
    const deleteRule = rule => props.setHolidayRulesList(props.holidayRulesList.filter(item => item !== rule));
    const [advancedSettingsOpen, setAdvancedSettingsOpen] = useState(false);

    const handleOpen = () => {
        setAdvancedSettingsOpen(!advancedSettingsOpen);
    };

    return (
        props.settingsComponent ? (
            <div>
            <Paper elevation={0}>
            <Toolbar className={classes.toolbar} onClick={handleOpen}>
                <Grid
                    className={classes.header}
                    container
                    direction="row"
                    justifyContent="space-between"
                    component="label"
                    alignItems="center"
                >
                    <Grid item>
                        <Typography>
                            Advanced Settings
                        </Typography>
                    </Grid>
                    <Grid item>
                        {!advancedSettingsOpen ? (
                            <ArrowDropDownOutlinedIcon
                                fontVariant="outlined"
                                color="primary"
                                fontSize="large"
                                onClick={handleOpen}
                            />
                        ) : (
                            <ArrowDropUpOutlinedIcon
                                fontVariant="outlined"
                                color="primary"
                                fontSize="large"
                                onClick={handleOpen}
                            />
                        )}
                    </Grid>
                </Grid>
            </Toolbar>
                <Collapse in={advancedSettingsOpen}>
                    <Typography className={classes.heading}>Holiday responding rules</Typography>
                    <Divider  className={classes.divider}/>
                    <HolidayWeekdayRulesTable
                        holiday={props.holiday}
                        rules={props.holidayRulesList}
                        onChange={updateRule}
                        onDelete={deleteRule}
                    />
                    <AddNewWeekdayRule
                        holiday={props.holiday}
                        defaultRule={props.holidayRulesList[0]}
                        addNewRule={addRule}
                        croppedName={props.croppedName}
                    />
                </Collapse>
</Paper>
            </div>
        ) : (
            <Paper className={classes.box}>
                <Typography className={classes.heading}>Holiday responding rules</Typography>
                <Divider  className={classes.divider}/>
                <HolidayWeekdayRulesTable
                    holiday={props.holiday}
                    rules={props.holidayRulesList}
                    onChange={updateRule}
                    onDelete={deleteRule}
                />
                <AddNewWeekdayRule
                    holiday={props.holiday}
                    defaultRule={props.holidayRulesList[0]}
                    addNewRule={addRule}
                    croppedName={props.croppedName}
                />
            </Paper>
        )
    );
}
