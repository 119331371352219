import React from 'react';
import {
  addMonths,
  format, subMonths,
} from 'date-fns';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Typography} from "@material-ui/core";
import {ArrowLeft, ArrowRight} from '@material-ui/icons';


const useStyles = makeStyles({
  root: {
    justifyContent: "space-between",
  },
});

export default function CalendarHeader(props) {
  const currentMonth = props.currentMonth;
  const setCurrentMonth = props.setCurrentMonth;
  const classes = useStyles();
  const dateFormat = "MMMM, yyyy";

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Button onClick={prevMonth}>
        <ArrowLeft />
      </Button>
      <Typography component="h1" variant="h6" color="inherit" noWrap>{format(currentMonth, dateFormat)}</Typography>
      <Button onClick={nextMonth}>
        <ArrowRight />
      </Button>
    </Grid>
  );

}

