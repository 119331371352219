import React, {useEffect, useState} from 'react';
import CalendarHeader from "./CalendarHeader";
import Days from "./Days";
import { makeStyles } from '@material-ui/core/styles';
import Cells from "./Cells";
import { useAPI } from '../../common/API';
import UpdateEventModal from "./EventModal/UpdateEventModel";
import {Button, Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {parseErrors} from "../../utils/ parseErrors";




const useStyles = makeStyles({
  root: {
    width: '100%',
    textAlign: "center",
  },
  container: {
    maxHeight: 550,
  },
  searchBar: {
    margin: 20,
    marginBottom: 60,
  }
});

export default function Calendar() {
  const classes = useStyles();
  const api = useAPI();
  const date = new Date();
  const slDate = new Date();

  const [vacations, setVacations] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(date);
  const [selectedDate, setSelectedDate] = useState(slDate);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const month = currentMonth.getUTCMonth() + 1;
  const year = currentMonth.getUTCFullYear();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [createAlert, setCreateAlert] = useState(null);
  const [updateAlert, setUpdateAlert] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(null);
  const [errors, setErrors] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
    setCreateAlert(null);
    setUpdateAlert(null);
    setDeleteAlert(null);
    setErrors([]);
  };

  useEffect(() => {
    api.get(`api/v1/holidays?month=${month}&year=${year}&for_wizard=True`)
      .then(res => res.json())
      .then(data => {
        data && setVacations(data);
      });
  }, [modalVisible, currentMonth]);

  useEffect(() => {
    if (createAlert || updateAlert || deleteAlert) {
      setModalVisible(false);
    }
  }, [createAlert, updateAlert, deleteAlert]);

  const getAlert = () => {
    if (createAlert){
      return (
        <Alert onClose={handleClose} severity="success" xs={{ width: '100%' }}>
          Holiday successfully created!
        </Alert>
      );
    } else if (updateAlert){
      return (
        <Alert onClose={handleClose} severity="success" xs={{ width: '100%' }}>
          Holiday successfully updated!
        </Alert>
      );
    } else if (deleteAlert){
      return (
        <Alert onClose={handleClose} severity="success" xs={{ width: '100%' }}>
          Holiday successfully deleted!
        </Alert>
      );
    }

    if (errors.length !== 0){
        return(
            <Alert onClose={handleClose} severity="error" xs={{ width: '100%' }}>
              {parseErrors(errors[0])}
            </Alert>
        );
    }
  };

  return (
    <div className={classes.root}>
      <Button variant="outlined" onClick={() => setCurrentMonth(new Date())}>Today</Button>
      <CalendarHeader
        currentMonth={currentMonth}
        setCurrentMonth={setCurrentMonth}
      />
      <Days currentMonth={currentMonth} />
      <Cells
        currentMonth={currentMonth}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        setSelectedEvent={setSelectedEvent}
        setModalVisible={setModalVisible}
        events={vacations}
        handleSnackClose={handleClose}
      />
      {modalVisible && selectedEvent &&
          <UpdateEventModal
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            day={selectedDate}
            vacation={selectedEvent}
            updateAlert={updateAlert}
            setUpdateAlert={setUpdateAlert}
            deleteAlert={deleteAlert}
            setDeleteAlert={setDeleteAlert}
            setOpenSnackbar={setOpenSnackbar}
            setErrors={setErrors}
          />
      }
      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleClose}>
        {getAlert()}
      </Snackbar>
    </div>
  );

}

