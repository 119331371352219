import {
    FormControl,
    IconButton,
    Input,
    makeStyles,
    Switch,
    TableCell,
    TableRow
} from '@material-ui/core';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  disabledRow: {
    backgroundColor: theme.palette.action.hover,
  }
}));


export default function EmailRuleRow(props) {
  const classes = useStyles();

  const enable = enabled => props.onChange({...props, enabled});

  return (
    <TableRow className={clsx(!props.enabled && classes.disabledRow)}>
      <TableCell padding="checkbox">
        <FormControl margin="dense">
          <Switch checked={props.enabled}
                  onChange={e => enable(e.target.checked)} color="primary" />
        </FormControl>
      </TableCell>
      <TableCell>
          <Input
            inputMode="text"
            fullWidth={true}
            value={props.email}
            readOnly={true}
          />
      </TableCell>
      <TableCell>
          <Input
            inputMode="text"
            fullWidth={true}
            value={props.reply_from}
            readOnly={true}
          />
      </TableCell>
      <TableCell style={{textAlign: "end"}}>
        <IconButton
          style={{padding: '8px'}}
          color="secondary"
          onClick={() => props.onDelete(props.id)}
        ><DeleteIcon/></IconButton>
      </TableCell>
    </TableRow>
  );
}
