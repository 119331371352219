import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Toolbar,
  Tooltip,
} from '@material-ui/core';
import { KeyboardDateTimePicker, KeyboardTimePicker } from '@material-ui/pickers';
import {days, formatTime, getDefaultDate, parseDateTime} from './tools';


export default function NewOfficeTiming(props) {
  const holidaysOnlyWarningText = `
    To enable Response timings on weekdays, please contact our support team support@ezcloudllc.com
  `;
  const initialOT = {
    enabled: true,
    day: null,
    all_day: false,
    start_time: null,
    end_time: null,
  };
  const [ot, setOT] = useState(initialOT);
  const setValue = value => setOT({...ot, ...value});

  const setDefaultDate = (hours, minutes, start) => {
    const date = getDefaultDate(hours, minutes);
    if (start){
      setValue({start_time: date});
    } else {
      setValue({end_time: date});
    }
    return date;
  };

  return (
    <Toolbar style={{padding: '15px'}}>
      <Grid container direction="row" justify="space-between" spacing={1} alignItems="center">
        <Grid item>
          <Tooltip
              title={props.holidaysOnly ? holidaysOnlyWarningText : ""}
              placement="bottom-start"
              enterDelay={300}
          >
            <FormControl disabled={props.holidaysOnly} style={{minWidth: '120px'}} margin="dense">
              <InputLabel id="select-day-label">Day</InputLabel>
              <Select
                  value={ot.day}
                  labelId="select-day-label"
                  onChange={e => setValue({day: e.target.value, start_time: null})}
              >
                {days.map(item => <MenuItem
                    key={item.value}
                    value={item.value}>{item.label}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Tooltip>
        </Grid>
        {ot.day !== null ? <React.Fragment>
          <Grid item>
          <FormControl margin="dense">
            <FormControlLabel
              label="All Day"
              control={<Switch checked={ot.all_day}
                               onChange={e => setValue({all_day: e.target.checked})} />}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <KeyboardTimePicker
            label="Start Time"
            margin="dense"
            ampm={true}
            disabled={ot.all_day}
            value={ot.start_time ? parseDateTime(ot.start_time) : setDefaultDate(17, 0, true)}
            onChange={value => setValue({start_time: formatTime(value)})}
            showTodayButton
            todayLabel="now"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
        <Grid item>
          <KeyboardTimePicker
            label="End Time"
            margin="dense"
            ampm={true}
            disabled={ot.all_day}
            value={ot.end_time ? parseDateTime(ot.end_time) : setDefaultDate(23, 59, false)}
            onChange={value => setValue({end_time: formatTime(value)})}
            showTodayButton
            todayLabel="now"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
        </React.Fragment> : <React.Fragment>
          <Grid item>
          <KeyboardDateTimePicker
            label="Start Date"
            margin="dense"
            ampm={true}
            value={ot.start_time ? parseDateTime(ot.start_time) : setDefaultDate(12, 0, true)}
            onChange={value => setValue({start_time: formatTime(value)})}
            showTodayButton
            todayLabel="now"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
        <Grid item>
          <KeyboardDateTimePicker
            label="End Date"
            margin="dense"
            ampm={true}
            value={ot.end_time ? parseDateTime(ot.end_time) : setDefaultDate(23, 59, false)}
            onChange={value => setValue({end_time: formatTime(value)})}
            showTodayButton
            todayLabel="now"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
        </React.Fragment>}
        <Grid item>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
              props.addOT(ot);
              setOT(initialOT);
            }}
            >Add</Button>
        </Grid>
      </Grid>
    </Toolbar>
  );
}
