import React from 'react';
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles({
  root: {
    marginBottom: 15,
  },
  datePicker: {
    width: '65%',
    marginRight: '5%',
  },
  timePicker: {
    width: '30%'
  }
});

const EventDateTimePicker = ({selectedDate, setSelectedDate, dateLabel, timeLabel}) => {
  const classes = useStyles();
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return(
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container>
          <KeyboardDatePicker
            className={classes.datePicker}
            label={dateLabel}
            format="MM/dd/yyyy"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            disabled
          />
          <KeyboardTimePicker
            className={classes.timePicker}
            label={timeLabel}
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
            disabled
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default EventDateTimePicker;