import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import {TextField} from "@material-ui/core";
import EventDateTimePicker from "./EventDateTimePicker";


const useStyles = makeStyles(theme => ({
    paper: {
        position: "absolute",
        width: 500,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: "none",
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit / 2,
        top: theme.spacing.unit / 2,
        color: theme.palette.grey[500],
    },
    input: {
        width: '100%',
        marginTop: 5,
        marginBottom: 5,
    },
    title: {
        marginBottom: 10,
    },
    buttons: {
        marginTop: 15,
    },
    button: {
        paddingRight: 30,
        paddingLeft: 30
    },
    switch: {
        marginTop: 15,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    }
}));

function getModalStyle() {
    return {
        top: '20%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };
}

const UpdateEventModal = (
    {
        modalVisible,
        setModalVisible,
        vacation
    }
) => {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [startDate, setStartDate] = useState(new Date(vacation.start_date));
    const [endDate, setEndDate] = useState(new Date(vacation.end_date));

    const handleClose = () => {
        setModalVisible(false);
    };

    return(
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={modalVisible}
            onClose={handleClose}
        >
            <div style={modalStyle} className={classes.paper}>
                <div style={{textAlign: "center",}}>
                    <Typography className={classes.title} variant="h6" id="modal-title">
                        Event Details
                    </Typography>
                    <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <EventDateTimePicker
                        selectedDate={startDate}
                        setSelectedDate={setStartDate}
                        dateLabel="Start date"
                        timeLabel="Start time"
                    />
                    <EventDateTimePicker
                        disabled={true}
                        selectedDate={endDate}
                        setSelectedDate={setEndDate}
                        dateLabel="End date"
                        timeLabel="End time"
                    />
                    <TextField
                        label="Holiday Name"
                        id="holiday-name"
                        className={classes.input}
                        defaultValue={vacation.holiday_name}
                        disabled
                    />
                </div>
            </div>

        </Modal>
    );
};

export default UpdateEventModal;