import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableContainer,
  Toolbar,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import CustomEditor from "./Editor";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@material-ui/icons/ArrowDropUpOutlined";
import DeterminateLinearProgress
  from "../../../utils/DeterminateLinearProgress";


const useTemplateEditorStyles = makeStyles(theme => ({
  disabled: {
    backgroundColor: theme.palette.action.hover,

  },
  root: {
    transition: theme.transitions.create('background-color', {
      duration: '300ms',
    }),
    marginBottom: 15
  },
  header: {
    cursor: 'pointer',
  },
  bottomAddPanel: {
    width: 'auto',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  toolbar: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  template: {
    padding: theme.spacing(2),
  },
  templateHelp: {
    paddingLeft: theme.spacing(2),
  }
}));


export default function TemplateEditor(props) {
  const classes = useTemplateEditorStyles();
  const [openSettings, setOpenSettings] = useState(false);
  const respStatus = props.respStatus;
  const [sendingReq, setSendingReq] = useState(false);

  const update = (value) => {
    props.setOpenSnackbar(false);
    setSendingReq(true);
    props.onChange(value);
  };

  useEffect(() => {
    if(respStatus){
      setSendingReq(false);
      props.setRespStatus(null);
    }
  }, [respStatus]);

  const handleOpen = () => {
    setOpenSettings(!openSettings);
  };

  return (
    <Paper className={clsx(classes.root, !openSettings && classes.disabled)}>
      <DeterminateLinearProgress visible={sendingReq} opened={openSettings}/>
      <Toolbar className={classes.toolbar} onClick={handleOpen}>
        <Grid className={classes.header} container direction="row"
              justify="space-between" component="label">
          <Grid item>
              <Typography variant="h6" component="div" > Template message</Typography>
          </Grid>
          <Grid item>
            {!openSettings ? (
                <ArrowDropDownOutlinedIcon fontVariant="outlined" color="primary" fontSize="large" onClick={handleOpen} />
            ) : (
                <ArrowDropUpOutlinedIcon fontVariant="outlined" color="primary" fontSize="large" onClick={handleOpen} />
            )}
          </Grid>
        </Grid>
      </Toolbar>
      <Collapse in={openSettings}>
        <TableContainer>
          <Table size="small" aria-label="simple table" >
            <Typography variant="body" component="div" className={classes.templateHelp} > To use the recipient&apos;s first name, use #firstname. This may appear unprofessional if the email is sent from the company name.</Typography>
            <div className={classes.template}>
              <CustomEditor
                templateMessage={props.templateMessage}
                onChange={update}
              />
            </div>
          </Table>
        </TableContainer>
      </Collapse>
    </Paper>
  );
}
