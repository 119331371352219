import React from 'react';
import {Button, Grid, makeStyles, Paper, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        flexGrow: 1,
    },
    buttons: {
        textAlign: "end",
    },
}));


export const QuestionBoxContent = ({ enabled, questionText, handleClick }) => {
    const classes = useStyles();
    return (
        <div>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={10}>
                    <Typography>
                        {questionText}
                    </Typography>
                </Grid>
                <Grid container item xs={2} className={classes.buttons}>
                    <Grid item xs={6}>
                        <Button
                            variant={enabled && typeof enabled === "boolean" ? "contained" : "outlined"}
                            color="primary"
                            size="small"
                            onClick={() => handleClick(true)}
                        > Yes </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant={enabled || typeof enabled !== "boolean" ? "outlined" : "contained"}
                            color="primary"
                            size="small"
                            onClick={() => handleClick(false)}
                        > No </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};


export const QuestionBox = ({ enabled, questionText, handleClick }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <QuestionBoxContent
                enabled={enabled}
                questionText={questionText}
                handleClick={handleClick}
            />
        </Paper>
    );
};