import React from 'react';

export const ignore = (fun, printErr) => {
  try {
    return fun();
  } catch (e) {
    if (printErr) {
      console.log(e);
    }
    return null;
  }
};

export const formatTime = time => time ? ignore(() => time.toISOString()) : null;
export const parseDateTime = time => time ? ignore(() => new Date(time)) : null;

export const getDefaultDate = (hours, minutes) => {
  const defDate = new Date();
  defDate.setHours(hours, minutes);
  return defDate;
};

export const days = [
  {label: <em>None</em>, value: null},
  {label: "Monday", value: 1},
  {label: "Tuesday", value: 2},
  {label: "Wednesday", value: 3},
  {label: "Thursday", value: 4},
  {label: "Friday", value: 5},
  {label: "Saturday", value: 6},
  {label: "Sunday", value: 7},
];
