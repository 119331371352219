import React, {useEffect, useState} from 'react';
import WizardStepper from "./WizardStepper";
import {useAPI} from "../../common/API";
import {WizardSettings} from "./WizardSettings";


export default function CalendarWizard () {
    const api = useAPI();
    const [holidays, setHolidays] = useState();
    const [settings, setSettings] = useState();
    const [succeedUpdate, setSucceedUpdate] = useState(null);

    const updateSettings = (data) => settings && api.patch(`api/v1/settings/${settings.id}/`, {
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(res => {
                const respStatus = res.status;
                setSucceedUpdate(respStatus === 200);
                return res.json();
            })
            .then(res => setSettings(res));

    const getHolidaysData = () => {
        api.get(`api/v1/holidays?for_wizard=True`)
            .then(res => res.json())
            .then(res => {
                res && setHolidays(res);
            });
    };

    const getSettingsData = () => {
        api.get('api/v1/settings/')
            .then(res => res.json())
            .then(res => {
                res && setSettings(res[0]);
            });
    };

    const updateHebcalSettings = (hebcal_settings) =>
        settings && settings.hebcal_settings && api.patch(
            `api/v1/hebcal-settings/${settings.hebcal_settings.id}`,{
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({...hebcal_settings}),
            })
            .then(res => {
                const respStatus = res.status;
                setSucceedUpdate(respStatus === 200);
                return res.json();
            })
            .then(res => {
              let zip_code = "";
              if (Array.isArray(res.zip_code)){
                zip_code = res.zip_code[0];
              } else {
                zip_code = res.zip_code;
              }
              setSettings(prevState => {
                return ({...prevState, hebcal_settings: {...prevState.hebcal_settings, zip_code: zip_code}});
              });
            });

    useEffect(() => {
        getSettingsData();
        getHolidaysData();
    }, []);

    return(
       <div>
           {holidays && settings && !settings.wizard_completed && <WizardStepper
               holidays={holidays}
               setHolidays={setHolidays}
               updateSettings={updateSettings}
               succeedUpdate={succeedUpdate}
               setSucceedUpdate={setSucceedUpdate}
               settings={settings}
               getHolidaysData={getHolidaysData}
               updateHebcalSettings={updateHebcalSettings}
           />}
           {holidays && settings && settings.wizard_completed && <WizardSettings
               holidays={holidays}
               setHolidays={setHolidays}
               succeedUpdate={succeedUpdate}
               setSucceedUpdate={setSucceedUpdate}
               settings={settings}
               updateSettings={updateSettings}
               updateHebcalSettings={updateHebcalSettings}
               getHolidaysData={getHolidaysData}
           />}
       </div>
    );
}