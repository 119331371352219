import { Button, Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import Editor from "ckeditor5-custom-build-with-file-upload/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { CKEDITOR_UPLOAD_URL } from "common/variables";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    saveButton: {
        marginTop: 10,
    },
}));

export default function CustomEditor(props) {
    const classes = useStyles();
    const editorConfiguration = {
        simpleUpload: {
            uploadUrl: CKEDITOR_UPLOAD_URL,
        },
        title: "",
        removePlugins: ["Title"],
    };
    const [messageFromEditor, setMessageFromEditor] = useState(
        props.templateMessage
    );

    return (
        <div>
            <Grid
                className={clsx(classes.editor)}
                container
                direction="column"
                justify="space-between"
                alignItems="stretch"
            >
                <Grid item>
                    <CKEditor
                        editor={Editor}
                        config={editorConfiguration}
                        data={props.templateMessage}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setMessageFromEditor(data);
                        }}
                    />
                </Grid>
                <Grid
                    className={clsx(classes.saveButton)}
                    container
                    direction="row"
                    justify={
                        props.buttonPosition ? props.buttonPosition : "flex-end"
                    }
                    alignItems="flex-end"
                >
                    <Button
                        size={props.buttonSize ? props.buttonSize : "small"}
                        variant="contained"
                        color="primary"
                        onClick={() => props.onChange(messageFromEditor)}
                    >
                        {props.buttonText ? props.buttonText : "Save"}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}
