import React, {useEffect} from 'react';
import { useLocalStorage } from '../../common/utils';
import { authUser } from "../../common/net";
import { useHistory} from 'react-router-dom';


export function HijackUser() {
  const [, setToken] = useLocalStorage('hijackToken', null);
  const [, setUser] = useLocalStorage('hijackUserObj', null);
  const splittedUrl = useHistory().location.pathname.split('/').filter(e => e !== '');

  useEffect(async () => {
    localStorage.setItem('token', null);
    localStorage.setItem('user', null);
    await setToken(splittedUrl[1]);
    const user = await authUser(splittedUrl[1]);
    await setUser(user);

    localStorage.setItem('hijackUser', 'true');
    window.location.href="/";
  }, []);

  return (
    <div />
  );
}
