import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Typography from "@material-ui/core/Typography";
import {useAuth} from "../common/auth";
import {useHistory} from "react-router-dom";


export default function UserMenu (props) {
  const auth = useAuth(),
    history = useHistory();

  const logout = resp => auth.signOut(resp).then(() => history.push('/'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
      setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    logout();
  };

  const handleRelease = () => {
    setAnchorEl(null);
    props.releaseUser();
  };

  return(
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
          {props.hijacked === 'true' ? (
              <MenuItem onClick={handleRelease}>
                 <Typography>
                     Release user
                 </Typography>
              </MenuItem>
          ) : (
              <MenuItem onClick={handleLogout}>
                 <Typography>
                     Logout
                 </Typography>
              </MenuItem>
          )}
      </Menu>
    </div>
  );
}
