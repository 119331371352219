import React, {useEffect, useState} from 'react';
import {
    Grid,
    Typography,
    makeStyles,
    FormControl, InputLabel, Select, MenuItem, TextField,
} from "@material-ui/core";
import {KeyboardTimePicker} from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
    box: {
        marginTop: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(2),
        flexGrow: 1,
    },
    selectors: {
        justifyContent: "flex-end",
    },
    text: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3),
    },
    label: {
        color: "#7b7b7b",
    }
}));

export default function HolidayEnd(props) {
    const classes = useStyles();
    const uncommonHolidays = ["Purim / פורים", "Tish'a B'Av / תשעה באב"];
    const croppedHolidayName = props.holiday.holiday_name.split('/')[0];

    const defaultRule = uncommonHolidays.includes(props.holiday.holiday_name) && props.holiday.respond_rules.length === 0? {
        fixed_end_time : '23:59',
        minutes_after : null
    } : props.holidayRulesList[0];

    const initialState = {
        fixed_end_time: defaultRule.fixed_end_time ? defaultRule.fixed_end_time : '23:59',
        minutes_after: defaultRule.minutes_after ? defaultRule.minutes_after : 0,
    };

    const [fixedEndTime, setFixedEndTime] = useState(initialState.fixed_end_time.split(':'));
    const setEndDate = () => {
        const date = new Date();
        date.setHours(fixedEndTime[0], fixedEndTime[1]);
        return date;
    };
    const [minAfterSundown, setMinAfterSundown] = useState(initialState.minutes_after);
    const [holidayEndDate,] = useState(() => setEndDate());
    const [endOption, setEndOption] = useState(defaultRule.minutes_after ? 'minutes' : 'fixed');

    const handleTimeOptionChange = (event) =>{
        setEndOption(event.target.value);
        if (!props.settingsComponent && croppedHolidayName === 'Rosh Hashana '){
            if (event.target.value === 'fixed') {
                props.roshHashanaRespondRules.fixed_end_time = fixedEndTime.join(':');
                props.roshHashanaRespondRules.minutes_after = null;
            } else {
                props.roshHashanaRespondRules.minutes_after = minAfterSundown;
                props.roshHashanaRespondRules.fixed_end_time = null;
            }
        }
    };

    const handleDateChange = (date) => {
        setFixedEndTime([date.getHours(), date.getMinutes()]);
        if (!props.settingsComponent && croppedHolidayName === 'Rosh Hashana ') {
            props.roshHashanaRespondRules.fixed_end_time = [date.getHours(), date.getMinutes()].join(":");
            props.roshHashanaRespondRules.minutes_after = null;
        }
    };

    const handleMinAfterSundown = (event) => {
        let minutes = event.target.value;
        let value;
        if (minutes < 0) {
            value = 0;
        } else if (minutes > 120) {
            value = 120;
        } else {
            value = minutes;
        }
        setMinAfterSundown(value);
        if (!props.settingsComponent && croppedHolidayName === 'Rosh Hashana ') {
            props.roshHashanaRespondRules.minutes_after = value;
            props.roshHashanaRespondRules.fixed_end_time = null;
        }
    };
    const hebcalHolidayText = `The default end time for the Autoresponder is set on ${props.endingDateText}
                               at 11:59PM. Or, you can select an amount of time after sundown in minutes instead.`;

    const updateRules = (attrs) => {
        props.setHolidayRules((prevState) => {
            let updatedRules = [];
            for (let i = 0; i < prevState.length; i++) {
                updatedRules.push({...prevState[i], ...attrs});
            }
            return updatedRules;
        });
    };

    useEffect(() => {
        holidayEndDate.setHours(fixedEndTime[0], fixedEndTime[1]);
        if(endOption === 'fixed'){
            updateRules({fixed_end_time: fixedEndTime.join(':'), minutes_after: null});
        } else {
            updateRules({minutes_after: parseInt(minAfterSundown), fixed_end_time: null});
        }
    }, [endOption, fixedEndTime, minAfterSundown, ]);


    return (
        <div className={classes.box}>
            <Grid container direction="row" justifyContent="center" alignItems="flex-end">
                {
                    !props.settingsComponent && <Grid item  xs={12} className={classes.text}>
                        {props.federal ? (
                            <Typography>
                                The default end time for the Autoresponder is set at {props.holiday.holiday_name} 11:59pm.
                                Or, you can select a specific end time.
                            </Typography>
                        ) : (
                            <Typography>
                                {hebcalHolidayText}
                            </Typography>
                        )}
                    </Grid>
                }
                <Grid item xs={6} style={{alignSelf: "center"}}>
                    <label htmlFor={"ending-text"} className={classes.label}>Responding end day</label>
                    {props.federal ? (
                        <Typography name={"ending-text"}>
                            {props.holiday.holiday_name}
                        </Typography>
                    ) : (
                        <Typography name={"ending-text"}>
                            {props.endingDateText}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={6} sm container spacing={3} className={classes.selectors}>
                    <Grid item xs={5}>
                        <FormControl fullWidth disabled={props.federal}>
                            <InputLabel id="demo-simple-select-label">End option</InputLabel>
                            <Select
                                value={endOption}
                                label="End option"
                                onChange={handleTimeOptionChange}
                            >
                                <MenuItem value={'fixed'}>Fixed time end</MenuItem>
                                <MenuItem value={'minutes'}>Minutes after sundown</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                        {endOption === 'fixed' ? (
                            <KeyboardTimePicker
                                label="Fixed end"
                                value={holidayEndDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                        ) : (
                            <TextField
                                required
                                label= "Minutes after sundown"
                                fullWidth
                                type="number"
                                onChange={handleMinAfterSundown}
                                value={minAfterSundown}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
