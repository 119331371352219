import React, { useEffect, useState } from "react";
import {
    InputLabel,
    FormControl,
    MenuItem,
    Grid,
    Button,
    makeStyles,
    Paper,
    Select,
    TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(4),
    },
    input: {
        width: "100%",
    },
    row: {
        marginBottom: theme.spacing(2),
        direction: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    updateButton: {
        textAlign: "center",
        paddingBottom: theme.spacing(2),
    },
}));

async function getPostalCode() {
    let data;

    await fetch(
        "https://ipgeolocation.abstractapi.com/v1/?api_key=4246423d445d4f7ea11dd905f335dd2f"
    )
        .then((response) => response.json())
        .then((json) => {
            data = json;
        });
    return data;
}

export default function HebcalSettings(props) {
    const classes = useStyles();
    const [settingsData, setSettingsData] = useState(props.hebcalSettings);
    const updateSettingsData = (value) => {
        setSettingsData({ ...settingsData, ...value });
    };
    const [israelCity, setIsraelCity] = useState(props.hebcalSettings.israel_city);
    const [country, setCountry] = useState(props.hebcalSettings.country);
    const [countries] = useState(props.hebcalSettings.country_options);
    const [israelCities] = useState(props.hebcalSettings.israel_city_options);
    useEffect(async () => {
        if (!settingsData.zip_code) {
            updateSettingsData({ zip_code: "11211" });
            const locationData = await getPostalCode();
            updateSettingsData({
                zip_code: locationData.postal_code ? locationData.postal_code : "11211",
            });
        }
    }, []);

    useEffect(() => {
        setSettingsData(props.hebcalSettings);
    }, [props.hebcalSettings]);

    const updateHebcalSettings = () => {
        props.setOpenSnackbar(false);
        props.onHebcalSettingsChange(settingsData);
        setSettingsData({});
        props.setCreateHolidays && props.setCreateHolidays(true);
        const interval = setInterval(() => props.getHolidaysData(), 2000);
        setTimeout(() => clearInterval(interval), 8000);
    };

    const respStatus = props.respStatus;

    useEffect(() => {
        if (respStatus) {
            props.setRespStatus(null);
        }
    }, [respStatus]);

    const validateMins = (minutes) => {
        if (minutes <= 0) {
            return 1;
        } else if (minutes > 120) {
            return 120;
        } else {
            return minutes;
        }
    };
    const countrySelect = (
        <Grid container className={classes.row} spacing={3}>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                    <InputLabel>Country</InputLabel>
                    <Select
                        required
                        value={country}
                        onChange={(e) => {
                            setCountry(e.target.value);
                            updateSettingsData({ country: e.target.value });
                        }}
                    >
                        {countries.map((x) => (
                            <MenuItem key={x.value} value={x.value}>
                                {x.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
    const citySelect = (
        <Grid container className={classes.row} spacing={3}>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                    <InputLabel>Israel City</InputLabel>
                    <Select
                        required
                        value={israelCity}
                        onChange={(e) => {
                            setIsraelCity(parseInt(e.target.value));
                            updateSettingsData({ israel_city: parseInt(e.target.value) });
                        }}
                    >
                        {israelCities.map((x) => (
                            <MenuItem key={x.value} value={x.value}>
                                {x.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
    const zipSelect = (
        <Grid container className={classes.row} spacing={3}>
            <Grid item xs={12} sm={6}>
                <TextField
                    className={classes.input}
                    label="Zip code"
                    InputLabelProps={{ shrink: true }}
                    required
                    value={settingsData.zip_code}
                    onChange={(e) => updateSettingsData({ zip_code: e.target.value })}
                />
            </Grid>
        </Grid>
    );

    return (
        <div>
            <Paper className={classes.root}>
                <Grid container className={classes.row} spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className={classes.input}
                            required
                            type="number"
                            label="Candle-lighting minutes before sundown"
                            defaultValue={settingsData.candle_minutes}
                            onChange={(e) =>
                                updateSettingsData({
                                    candle_minutes: validateMins(parseInt(e.target.value)),
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            className={classes.input}
                            required
                            type="number"
                            label="Havdalah minutes past down"
                            defaultValue={settingsData.havdalah_minutes}
                            onChange={(e) =>
                                updateSettingsData({
                                    havdalah_minutes: validateMins(parseInt(e.target.value)),
                                })
                            }
                        />
                    </Grid>
                </Grid>
                {countrySelect}
                {country === "IL" ? citySelect : zipSelect}
                <div className={classes.updateButton}>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={updateHebcalSettings}
                    >
                        Update
                    </Button>
                </div>
            </Paper>
        </div>
    );
}
