import React from 'react';
import {
  addDays, startOfWeek, startOfMonth, endOfMonth, endOfWeek, isSameMonth, isSameDay
} from "date-fns";
import {Box} from "@material-ui/core";
import Cell from "./Cell";


export default function Cells(props){
  const {
    currentMonth, events, setSelectedDate, setModalVisible, setSelectedEvent
  } = props;
  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(monthStart);
  const startDate = startOfWeek(monthStart);
  const endDate = endOfWeek(monthEnd);
  const rows = [];
  const today = new Date();
  let day = startDate;
  let days = [];
  const filteredEvents = events.filter(
    event =>
      new Date(event['start_date']) <= new Date(endDate) &&
      new Date(event['end_date']) > new Date(startDate)
  );

  const dateWithoutTime = (dateTime) => {
    let date = new Date(dateTime.getTime());
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  };

  const getVacationEvent = ({day}) => {
    const vacationEvent = filteredEvents[0];
    if (vacationEvent === undefined) {
      return null;
    }

    const dayDate = dateWithoutTime(new Date(day));
    const vacationStart = dateWithoutTime(new Date(vacationEvent['start_date']));
    const vacationEnd = dateWithoutTime(new Date(vacationEvent['end_date']));
    if(isSameDay(dayDate, vacationStart) && isSameDay(dayDate, vacationEnd)){
      const vacation = vacationEvent;
      filteredEvents.shift();
      return vacation;
    }
    else if ( dayDate === vacationStart){
      return vacationEvent;
    }
    else if (dayDate < vacationEnd && dayDate > vacationStart){
      return vacationEvent;
    }
    else if (dayDate === vacationEnd){
      const vacation = vacationEvent;
      filteredEvents.shift();
      return vacation;
    }

    return null;
  };

  const getDaysCells = () => {
    while (day <= endDate) {
      for (let i=0; i< 7; i++) {
        const vacationEvent = getVacationEvent({day});
        days.push(
          <Cell
              day={day}
              disabled={!isSameMonth(day, monthStart)}
              setSelectedDate={setSelectedDate}
              setModalVisible={setModalVisible}
              setSelectedEvent={setSelectedEvent}
              vacationEvent={vacationEvent}
              today={isSameDay(day, today)}
              handleClose={props.handleSnackClose}
          />
        );
        day = addDays(day, 1);
      }
      rows.push(
        <Box display="flex" flexDirection="row" p={1} m={1}>
          {days}
        </Box>
      );
      days = [];
    }
    return rows;
  };

  return(
    <Box>
      {getDaysCells()}
    </Box>
  );
}