import React, {useEffect, useState} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Button, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";


export default function EmailsFilters(props) {
  const [resolved, setResolved] = useState('False');

  let repliesFrom = props.emails.map((email)  => email.reply_from);
  repliesFrom = [...new Set(repliesFrom)];

  let repliesTo = props.emails.map((email)  => email.reply_to);
  repliesTo = [...new Set(repliesTo)];

  const handleChange = (name, value) => {
    props.setFilters(prevState => ({
        ...prevState,
        [name]: value
    }));
  };

    useEffect(() => {
        props.filters.resolved = resolved;
        props.onSearch(props.filters);
    }, [resolved]);


  return (
    <Grid container spacing={3} direction="row" justify="space-around" alignItems="center">
        <Grid item xs={12} sm={12} md={3}>
          <Autocomplete
            id="subject"
            options={props.emailsSubjects}
            getOptionLabel={(option) => option}
            onChange={(event, value) => handleChange(
              'subject', value
            )}
            size='small'
            variant="outlined"
            renderInput={(params) => <TextField {...params}
            label="Subject"
            variant="outlined"
            />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Autocomplete
            id="from"
            options={repliesFrom}
            getOptionLabel={(option) => option}
            onChange={(event, value) => handleChange(
              'reply_from', value
            )}
            size='small'
            renderInput={(params) => <TextField {...params}
            label="From"
            variant="outlined"
            />}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Autocomplete
            id="to"
            options={repliesTo}
            getOptionLabel={(option) => option}
            onChange={(event, value) => handleChange(
              'reply_to', value
            )}
            size='small'
            renderInput={(params) => <TextField {...params}
            label="To"
            variant="outlined" /> }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
            <FormControl fullWidth size="small" variant="outlined">
                <InputLabel>Resolved</InputLabel>
                <Select
                    value={resolved}
                    label="Resolved"
                    onChange={e => setResolved(e.target.value)}
                >
                    <MenuItem value={'None'}>All</MenuItem>
                    <MenuItem value={'True'}>Resolved</MenuItem>
                    <MenuItem value={'False'}>Unresolved</MenuItem>
                </Select>
            </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={1}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            onClick={props.onSearch}
          >
            Search
          </Button>
        </Grid>
    </Grid>
  );
}