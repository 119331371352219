import React from 'react';
import {Paper} from "@material-ui/core";
import {format} from "date-fns";
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme) => ({
  day: {
    width: "20%",
    height: theme.spacing(12),
    textAlign: "end",
    padding: 10,
    cursor: "pointer",
  },
  dateInfo: {
    margin: 10,
  },
  dateNum: {
    fontWeight: 600,
    marginBottom: 5,
  },
  holiday: {
    background: theme.palette.info.light,
    textAlign: "start",
  }
}));

const Cell = ({day, disabled, setSelectedDate, setModalVisible, vacationEvent, setSelectedEvent, today, handleClose}) => {
  const classes = useStyles();
  const formattedDate = format(day, 'd');
  const onDateClick = () => {
    setSelectedDate(day);
    setModalVisible(true);
    setSelectedEvent(vacationEvent);
    handleClose();
  };
  const bgColor = disabled ? grey[200] : null;
  const border = today ? {border: 3, borderStyle: "solid", borderColor: "#4051b5"} : null;
  return(
      <Paper
        style={{background: bgColor, ...border}}
        className={classes.day}
        variant='outlined'
        onClick={onDateClick}
      >
          <div className={classes.dateNum}>{formattedDate}</div>
          {vacationEvent && vacationEvent.holiday_name && (
            <Paper
              style={!vacationEvent.enable ? {background: grey[400]} : null}
              className={classes.holiday}
            >
              {vacationEvent.holiday_name}
            </Paper>
          )}
      </Paper>
  );
};

export default Cell;
