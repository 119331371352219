import {
  Checkbox, Collapse,
  FormControl,
  FormControlLabel,
  FormGroup, Grid,
  Input,
  makeStyles,
  Paper, Toolbar,
  Typography,
} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import ArrowDropDownOutlinedIcon
  from "@material-ui/icons/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@material-ui/icons/ArrowDropUpOutlined";
import clsx from "clsx";
import DeterminateLinearProgress from "../../utils/DeterminateLinearProgress";


const useStyles = makeStyles(theme => ({
  disabled: {
    backgroundColor: theme.palette.action.hover,
  },
  root: {
    marginBottom: 15
  },
  formControl: {
    padding: theme.spacing(2),
  },
  legend: {
    fontSize: '1.1rem',
    margin: 0,
    paddingTop: '20px',
  },
  toolbar: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  header: {
    cursor: 'pointer',
  },
}));


const filteringEmails = {
  all: {
    rule: /all/i,
    email: '@all',
  },
  walmart: {
    rule: /@relay\.walmart/i,
    email: '@relay.walmart',
  },
  amazon: {
    rule: /@marketplace\.amazon/i,
    email: '@marketplace.amazon',
  },
  ebay: {
    rule: /@members\.ebay/i,
    email: '@members.ebay',
  },
  newEgg: {
    rule: /@marketplace\.newegg/i,
    email: '@marketplace.newegg',
  },
};


function checkExists(emailType, str) {
  const check = o => o.rule.test(str.join(','));
  switch (emailType) {
    case 'allIndeterminate':
      return Object.values(filteringEmails).some(check);
    case 'all':
      return Object.values(filteringEmails).every(check);
    default:
      return check(filteringEmails[emailType]);
  }
}


function applyFilter(emailType, str, state) {
  const exists = checkExists(emailType, str);
  if (!(state ^ exists)) {
    return;
  }
  const emails = emailType === 'all'
    ? Object.values(filteringEmails).map(o => o)
    : [filteringEmails[emailType]];
  if (state) {
    return [...str, ...emails.map(o => o.email)];
  } else {
    return str.filter(val => !emails.some(o => o.rule.exec(val)));
  }
}


export default function FilterFrom({sentFrom, onChange, respStatus, setRespStatus, setOpenSnackbar}) {
  const classes = useStyles();
  const handledChecking = (emailType) => checkExists(emailType, sentFrom);
  const handledApplying = (emailType, state) => applyFilter(emailType, sentFrom, state);
  const [openSettings, setOpenSettings] = useState(false);
  const propsRespStatus = respStatus;
  const [sendingReq, setSendingReq] = useState(false);
  const [state, setState] = React.useState({
    walmart: false,
    amazon: false,
    ebay: false,
    newEgg: false,
    all: false,
    sentFrom: [],
    timeout: NaN,
  });

  const update = (value) => {
    setOpenSnackbar(false);
    setSendingReq(true);
    onChange(value);
  };

  useEffect(() => {
    if(propsRespStatus){
      setSendingReq(false);
      setRespStatus(null);
    }
  }, [propsRespStatus]);

  const handleChange = (event) => update(
    handledApplying(event.target.name, event.target.checked)
  );

  const handleInput = (e) => {
    clearTimeout(state.timeout);
    const sentFrom = e.target.value.split(',');
    setState({
      ...state,
      sentFrom,
      timeout: setTimeout(() => update(sentFrom), 1000),
    });
  };

  const handleOpen = () => {
        setOpenSettings(!openSettings);
    };

  React.useEffect(() => {
    setState({
      ...state,
      walmart: handledChecking('walmart'),
      amazon: handledChecking('amazon'),
      ebay: handledChecking('ebay'),
      newEgg: handledChecking('newEgg'),
      all: handledChecking('all'),
      allIndeterminate: handledChecking('allIndeterminate'),
      sentFrom,
    });
  }, [sentFrom]);

  return (
    <Paper className={clsx(classes.root, !openSettings && classes.disabled)}>
      <DeterminateLinearProgress visible={sendingReq} opened={openSettings}/>
      <Toolbar className={classes.toolbar} onClick={handleOpen}>
          <Grid
            className={classes.header}
            container direction="row"
            justify="space-between"
            component="label"
          >
              <Grid item>
                  <Typography variant="h6" component="div">
                      Filtering
                  </Typography>
              </Grid>
              <Grid item>
                  {!openSettings ? (
                      <ArrowDropDownOutlinedIcon fontVariant="outlined" color="primary" fontSize="large" onClick={handleOpen} />
                  ) : (
                      <ArrowDropUpOutlinedIcon fontVariant="outlined" color="primary" fontSize="large" onClick={handleOpen} />
                  )}
              </Grid>
          </Grid>
      </Toolbar>
      <Collapse in={openSettings}>
        <FormControl component="fieldset" fullWidth className={classes.formControl}>
          <FormGroup>
            <FormControl style={{minWidth: '120px'}} fullWidth variant="filled">
              <Input
                inputMode="text"
                value={state.all ? 'all':state.sentFrom}
                onBlur={() => update(state.sentFrom)}
                onChange={handleInput}
              />
            </FormControl>
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox
                checked={state.all}
                indeterminate={state.allIndeterminate && !state.all}
                onChange={handleChange}
                name="all"
              />}
              label="All"
            />
            <FormControlLabel
              control={<Checkbox checked={state.walmart} onChange={handleChange} name="walmart" />}
              label="Walmart"
            />
            <FormControlLabel
              control={<Checkbox checked={state.amazon} onChange={handleChange} name="amazon" />}
              label="Amazon"
            />
            <FormControlLabel
              control={<Checkbox checked={state.ebay} onChange={handleChange} name="ebay" />}
              label="eBay"
            />
            <FormControlLabel
              control={<Checkbox checked={state.newEgg} onChange={handleChange} name="newEgg" />}
              label="New Egg"
            />
          </FormGroup>
        </FormControl>
      </Collapse>
    </Paper>
  );
}