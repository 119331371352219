import React, {useState} from "react";
import {FormControl, FormControlLabel, Radio, RadioGroup, } from "@material-ui/core";

export default function StartDateRadioGroup(props) {
    const [radioValue, setRadioValue] = useState(props.defaultValue);

    const handleChange = (event) => {
        props.setValue(parseInt(event.target.value));
        setRadioValue(parseInt(event.target.value));
    };

    return (
        <div>
            <FormControl component="fieldset">
                <label htmlFor={"days-group"} style={{color: "#7b7b7b"}}>Responding start day</label>
                <RadioGroup
                    name={"days-group"}
                    aria-label="start-date"
                    value={radioValue}
                    onChange={handleChange}
                >
                    <FormControlLabel
                      value={props.first.value}
                      control={<Radio />}
                      label={props.first.text}
                    />
                    <FormControlLabel
                      value={props.second.value}
                      control={<Radio />}
                      label={props.second.text}
                    />
                </RadioGroup>
            </FormControl>
        </div>
    );
}