import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {WizardStepContent} from "./WizardStepContent";
import {Snackbar, StepLabel} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {parseErrors} from "../../utils/ parseErrors";
import {QuestionBox} from "./utils/QuestionBox";
import {FederalHoliday} from "./FederalHoliday";
import HebcalSettings from "../Settings/HebcalSettings";
import {useAPI} from "../../common/API";


const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	boxRoot: {
		marginTop: theme.spacing(3),
	},
	button: {
		marginRight: theme.spacing(1),
	},
	backButton: {
		marginRight: theme.spacing(1),
	},
	completed: {
		display: 'inline-block',
	},
	instructions: {
		textAlign: "center",
		marginTop: theme.spacing(3),
	},
	instructionsText: {
		margin: theme.spacing(2),
		marginLeft: theme.spacing(6),
		marginRight: theme.spacing(6),
	},
	mainButtons: {
		textAlign: "center",
		marginTop: theme.spacing(3),
	},
	submitButton: {
		paddingTop: theme.spacing(1),
		padding: theme.spacing(2),
		textAlign: "center",
	},
	holidayError: {
		textAlign: "center",
		margin: theme.spacing(3),
	},
	federalQuestionBox: {
		marginTop: theme.spacing(3),
	},
	stepperRoot: {
		cursor: "pointer"
	},
	creatingHolidays: {
		marginBottom: theme.spacing(8),
	}
}));

export const STEPS = [
	'Calendar settings',
	'Rosh Hashana / ראש השנה',
	'Yom Kippur / יום כפור',
	'Sukkot / סוכות',
	'Purim / פורים',
	'Pesach / פסח',
	'Shavuot / שבועות',
	"Tish'a B'Av / תשעה באב",
	"Federal holidays",
];

const CompleteComponent = (props) => {
	const classes = useStyles();
	const congratsText = "Thank you for choosing EZ Cloud’s Autoresponder. We hope this setup wizard helped you " +
		"get your Autoresponder customized to your needs quickly and accurately. If you have any questions please " +
		"contact our support team and we will be happy to assist you.";

	return (<div>
		<div className={classes.instructions}>
			<Typography className={classes.instructionsText}>
				{congratsText}
			</Typography>
			<Button variant="outlined" onClick={props.handleComplete} className={classes.button}>
				Complete
			</Button>
		</div>
	</div>);
};

export default function WizardStepper(props) {
	const api = useAPI();

	const federalHolidaysQuestionText = "Do you want the Autoresponder to respond on Federal Holidays?";
	const classes = useStyles();

	const [activeStep, setActiveStep] = React.useState(0);
	const [defaultMinStart, setDefaultMinStart] = React.useState(props.settings && props.settings.hebcal_settings.candle_minutes);
	const [enableFederalHolidays, setEnableFederalHolidays] = React.useState(
		props.settings && props.settings.enable_fed_holidays
	);

	const [holidaysSettings, setHolidaysSettings] = React.useState([]);
	const [creatingHolidays, setCreatingHolidays] = React.useState(false);


	const handleComplete = () => {
		props.updateSettings({"wizard_completed": true});
	};

	const completeStep = (activeStep) => {
		setCompletedSteps(prevState => prevState.add(activeStep));
	};

	const getCompletedValues = () => {
		const completed = [];

		const wizardHebHolidays = props.holidays.filter(
			holiday => holiday.for_wizard === true && holiday.uploaded_from === 'HEB'
		);
		const hebSettings = props.settings.hebcal_settings;
		const hebSettingsFields = [hebSettings.candle_minutes, hebSettings.havdalah_minutes, hebSettings.zip_code];
		if (wizardHebHolidays.length > 0 && hebSettingsFields.every(field => field !== null || field !== '')) {
			completed.push(0);
		}

		for (let i = 1; i < STEPS.length - 1; i++) {
			const filteredHolidays = props.holidays.filter(holiday => holiday.holiday_name === STEPS[i]);
			filteredHolidays.filter(holiday => typeof holiday.enable === 'boolean').length > 0 && completed.push(i);
		}
		typeof props.settings.enable_fed_holidays === 'boolean' && completed.push(STEPS.length - 1);
		return new Set(completed);
	};
	const [completedSteps, setCompletedSteps] = React.useState(() => getCompletedValues());

	const handleFederalHolidaysAvailability = (enable) => {
		setEnableFederalHolidays(enable);
		completeStep(activeStep);
		props.updateSettings({"enable_fed_holidays": enable});
		if (enable && props.holidays.filter(
			holiday => holiday.uploaded_from === 'FED'
		).length === 0) {
			setCreatingHolidays(true);
			const interval = setInterval(() => props.getHolidaysData(), 2000);
			setTimeout(() => clearInterval(interval), 8000);
		}

	};

	const [openSnackbar, setOpenSnackbar] = React.useState(false);
	const [successAlert, setSuccessAlert] = React.useState();
	const [errors, setErrors] = React.useState();
	const [respData, setRespData] = React.useState();

	const filteredHolidays = props.holidays.filter(holiday => holiday.holiday_name === STEPS[activeStep]);

	useEffect(() => {
		if (typeof props.succeedUpdate == "boolean") {
			if (!props.succeedUpdate) {
				setErrors([{setting: "Fail during settings update!"}]);
				setSuccessAlert(false);
			} else {
				setSuccessAlert(true);
			}
			setOpenSnackbar(true);
			props.setSucceedUpdate(null);
		}
	}, [props.succeedUpdate]);

	const handleResponse = (response) => {
		const respStatus = response.status;
		if (respStatus === 200) {
			setSuccessAlert(true);
		} else if (respStatus === 400) {
			setSuccessAlert(false);
		}
		setOpenSnackbar(true);
		return response.json();
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpenSnackbar(false);
		setErrors(null);
	};

	const closeCreatingHolidays = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setCreatingHolidays(false);
	};

	useEffect(() => {
		if (respData && !successAlert) {
			setErrors(Object.entries(respData));
		}
	}, [respData]);

	const handleStepperClick = (step) => {
		setActiveStep(step);
		if (!(step in [0, STEPS.length - 1])) {
			holidaysSettingsAutoUpdate();
		}
	};

	const generateHolidays = () => {
		setTimeout(() => props.getHolidaysData(), 3000);
		completeStep(0);
	};

	const updateHoliday = (data, holidayId) => {
		api.patch(`api/v1/holidays/${holidayId}`, {
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data)
		})
			.then(res => handleResponse(res))
			.then(res => {
				const index = props.holidays.findIndex(holiday => {
					return holiday.id === holidayId;
				});
				props.holidays[index] = res;
			})
		;
	};


	const holidaysSettingsAutoUpdate = () => {
		for (let i = 0; i < holidaysSettings.filter(setting => setting !== null).length; i++) {
			if (typeof holidaysSettings[i].data.enable === 'boolean') {
				updateHoliday(holidaysSettings[i].data, holidaysSettings[i].holidayId);
				completeStep(activeStep);
			}
		}
	};

	const handleNext = () => {
		setActiveStep((prevActiveStep) => {
			// 9 – the number of all steps in the wizard
			if (completedSteps.has(prevActiveStep + 1) && completedSteps.size !== 9) {
				for (let i = 1; i < 8; i++) {
					if (!completedSteps.has(prevActiveStep + i)) {
						return prevActiveStep + i;
					}
				}
			}
			return prevActiveStep + 1;
		});

		holidaysSettingsAutoUpdate();
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		holidaysSettingsAutoUpdate();
	};
	const [roshHashanaRespondRules,] = React.useState({
		fixed_start_time: "12:00",
		minutes_before: null,
		fixed_end_time: "23:59",
		minutes_after: null,
		days_before: 0,
	});

	const setUpdatedHolidaysData = (res) => {
		const holidaysList = [...props.holidays];
		const index = holidaysList.indexOf(props.holidays.filter(holiday => holiday.id === res.id)[0]);
		props.holidays[index] = res;
	};

	return (
		<div className={classes.root}>
			<Stepper nonLinear style={{cursor: "pointer"}} activeStep={activeStep}>
				{STEPS.map((holidayName, index) => {
					return (
						<Step
							completed={completedSteps.has(index)}
							onClick={() => {
								const holidayExists = props.holidays.filter(holiday => holiday.holiday_name === holidayName).length >= 1;
								if (holidayExists || index === STEPS.length - 1 || index === 0) {
									handleStepperClick(index);
								}

							}}
							key={holidayName}
						>
							<StepLabel>{holidayName}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
			<React.Fragment>
				{activeStep === 0 && <HebcalSettings
					hebcalVacationEnabled={props.settings ? props.settings.hebcal_vacation_enabled : false}
					hebcalSettings={props.settings.hebcal_settings}
					onChange={props.updateSettings}
					onHebcalSettingsChange={props.updateHebcalSettings}
					setOpenSnackbar={setOpenSnackbar}
					getHolidaysData={generateHolidays}
					setCreateHolidays={setCreatingHolidays}
				/>}
				{
					activeStep > 0 && activeStep < 8 && filteredHolidays.length > 0 && <WizardStepContent
						key={`${STEPS[activeStep]}`}
						allHolidays={props.holidays}
						holidays={filteredHolidays}
						defaultMinStart={defaultMinStart}
						setDefaultMinStart={setDefaultMinStart}
						setHolidaysData={props.setHolidays}
						handleResponse={handleResponse}
						setRespData={setRespData}
						completeSteps={completeStep}
						step={activeStep}
						stepsNames={STEPS}
						holidaysSettings={holidaysSettings}
						setHolidaysSettings={setHolidaysSettings}
						roshHashanaRespondRules={roshHashanaRespondRules}
						setUpdatedHolidaysData={setUpdatedHolidaysData}
					/>
				}
				{
					activeStep === 8 && <div>
						<div className={classes.federalQuestionBox}>
							<QuestionBox
								enabled={enableFederalHolidays}
								questionText={federalHolidaysQuestionText}
								handleClick={handleFederalHolidaysAvailability}
							/>
						</div>
						{enableFederalHolidays && props.holidays.filter(
							holiday => holiday.uploaded_from === 'FED'
						).sort((a, b) => new Date(b.start_date) - new Date(a.start_date)).reverse().map(
							holiday => <div className={classes.boxRoot} key={holiday.id}>
								<FederalHoliday
									holiday={holiday}
									handleResponse={handleResponse}
									setRespData={setRespData}
									allHolidays={props.holidays}
								/>
							</div>
						)}
					</div>
				}
				{
					activeStep === 9 && <CompleteComponent handleComplete={handleComplete} />
				}
				<Snackbar
					className={classes.creatingHolidays}
					open={creatingHolidays}
					autoHideDuration={4000}
					onClose={closeCreatingHolidays}
				>
					<Alert onClose={closeCreatingHolidays} severity="info" xs={{width: '100%'}}>
						We are creating holidays that could take a few seconds.
					</Alert>
				</Snackbar>
				<div className={classes.mainButtons}>
					<Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
						Back
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={handleNext}
						className={classes.button}
					>
						Next
					</Button>
				</div>
			</React.Fragment>
			{
				successAlert && (
					<Snackbar open={openSnackbar} autoHideDuration={1000} onClose={handleClose}>
						<Alert onClose={handleClose} severity="success" xs={{width: '100%'}}>
							Settings successfully updated!
						</Alert>
					</Snackbar>
				)
			}
			{
				!successAlert && errors && errors.length > 0 && (
					<Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleClose}>
						<Alert onClose={handleClose} severity="error" xs={{width: '100%'}}>
							{parseErrors(...errors)}
						</Alert>
					</Snackbar>
				)
			}
		</div>
	);
}
