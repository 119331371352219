import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useAPI } from "../../common/API";
import EmailsFilters from "./EmailsFilters";
import { omitBy } from "lodash";
import { stringify } from "qs";
import Paper from "@material-ui/core/Paper";
import {
    Button,
    Snackbar,
    TableSortLabel,
    Tooltip,
    withStyles,
    Grid,
    TextField,
} from "@material-ui/core";
import { handleResponse } from "../../utils/handleResponse";
import { parseErrors } from "../../utils/ parseErrors";
import { Alert } from "@material-ui/lab";
import { green } from "@material-ui/core/colors";
import tooltipImg from "../../assets/tooltipImg.png";

const columns = [
    { id: "subject", label: "Subject", minWidth: 170 },
    { id: "reply_from", label: "From", minWidth: 100 },
    { id: "reply_to", label: "To", minWidth: 170 },
    {
        id: "created_at",
        label: "Reply Time",
        minWidth: 170,
    },
    { id: "resolved", label: "Resolved", minWidth: 170, align: "center" },
];

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    container: {
        maxHeight: 550,
    },
    searchBar: {
        margin: 20,
        marginBottom: 60,
    },
    ellipsis: {
        maxWidth: 200,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    tooltipImg: {
        borderRadius: 3,
        maxWidth: "100%",
    },
    row: {
        paddingLeft: 15,
        paddingRight: 15,
        marginBottom: 10,
        direction: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
});

const SuccessButton = withStyles(() => ({
    root: {
        color: "white",
        backgroundColor: green[500],
        "&:hover": {
            backgroundColor: green[700],
        },
    },
}))(Button);

export default function RepliedEmails() {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [emails, setEmails] = useState([]);
    const [emailsSubjects, setEmailSubjects] = useState([]);
    const [filters, setFilters] = useState({
        subject: null,
        reply_from: null,
        reply_to: null,
        // here uses boolean as string because there is problem with 'all' which value is null
        resolved: "False",
    });
    const [accNumber, setAccNumber] = useState(0);
    const api = useAPI();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [successAlert, setSuccessAlert] = useState();
    const [respData, setRespData] = useState();
    const [errors, setErrors] = useState();
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("resolved");
    const [settings, setSettings] = useState({});

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
        setErrors(null);
    };

    const onSearch = (searchFilters) => {
        api.get(
            `api/v1/replied-emails?${stringify(
                getRequestParams(searchFilters)
            )}`
        )
            .then((res) => res.json())
            .then((res) => {
                setEmails(res);
            });
    };
    const zeroInDateElements = (val) => {
        if (val < 10) {
            return "0" + val;
        }
        return val;
    };

    const convertDateTime = (dateTime) => {
        const date = new Date(dateTime);
        const year = date.getFullYear();
        const month = zeroInDateElements(date.getMonth() + 1);
        const dt = zeroInDateElements(date.getDate());
        const hours = zeroInDateElements(date.getHours());
        const minutes = zeroInDateElements(date.getMinutes());

        return year + "-" + month + "-" + dt + " " + hours + ":" + minutes;
    };

    const onRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const getComparator = (order, orderBy) => {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const handleResolve = (messageId, resolved) => {
        updateMessage(messageId, resolved);
    };

    const updateMessage = (messageId, resolved) => {
        api.patch(`api/v1/replied-email/${messageId}`, {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                resolved: resolved,
            }),
        })
            .then((res) =>
                handleResponse(res, setSuccessAlert, setOpenSnackbar)
            )
            .then((res) => setRespData(res));
    };

    const getRequestParams = () => {
        return omitBy(filters, (val) => !(val || val === 0));
    };

    const getTooltipContent = () => {
        return (
            <div>
                If you are logged in to more then 1 google account please add
                the account # so it will open the proper email account when you
                click on the email in the Logs tab. See attached image on how to
                locate account #
                <img
                    src={tooltipImg}
                    alt="Tooltip helper image"
                    className={classes.tooltipImg}
                />
            </div>
        );
    };
    const updateAccountNumber = () => {
        api.patch(`api/v1/settings/${settings.id}/`, {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                gmail_account_number: accNumber,
            }),
        }).then((res) => handleResponse(res, setSuccessAlert, setOpenSnackbar));
    };

    useEffect(() => {
        api.get(`api/v1/replied-emails?${stringify(getRequestParams())}`)
            .then((res) => res.json())
            .then((res) => {
                setEmails(res);
                const subjects = res.map((email) => {
                    return email.subject;
                });
                const uniqueSubjects = [...new Set(subjects)];
                const noSubjectIndex = uniqueSubjects.indexOf("");
                if (noSubjectIndex !== -1) {
                    uniqueSubjects.splice(noSubjectIndex, 1);
                }
                setEmailSubjects(uniqueSubjects);
            });
        api.get("api/v1/settings/")
            .then((res) => res.json())
            .then((res) => {
                setAccNumber(res[0].gmail_account_number);
                setSettings(res[0]);
            });
    }, []);

    useEffect(() => {
        if (successAlert) {
            onSearch(filters);
        } else {
            if (respData) {
                setErrors(Object.entries(respData));
            }
        }
    }, [respData]);

    return (
        <div>
            <div>
                <Grid
                    container
                    direction="row"
                    spacing={3}
                    className={classes.row}
                >
                    <Grid item xs={9}></Grid>
                    <Grid item xs={2}>
                        <Tooltip title={getTooltipContent()}>
                            <TextField
                                label="Account number"
                                style={{ width: "100%" }}
                                size="small"
                                type="number"
                                variant="outlined"
                                onChange={(event) =>
                                    setAccNumber(
                                        event.target.value < 0
                                            ? (event.target.value = 0)
                                            : event.target.value
                                    )
                                }
                                value={accNumber}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: "end" }}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={updateAccountNumber}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.searchBar}>
                {emails.length !== 0 && (
                    <EmailsFilters
                        emails={emails}
                        emailsSubjects={emailsSubjects}
                        onSearch={onSearch}
                        filters={filters}
                        setFilters={setFilters}
                    />
                )}
            </div>
            <Paper>
                <TableContainer className={classes.container} container={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) =>
                                    column.label === "Resolved" ? (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                            }}
                                        >
                                            <TableSortLabel
                                                active={orderBy === column.id}
                                                direction={
                                                    orderBy === column.id
                                                        ? order
                                                        : "asc"
                                                }
                                                onClick={createSortHandler(
                                                    column.id
                                                )}
                                            >
                                                {column.label}
                                            </TableSortLabel>
                                        </TableCell>
                                    ) : (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    )
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {emails
                                .slice()
                                .sort(getComparator(order, orderBy))
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.code}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                if (column.id === "subject") {
                                                    return (
                                                        <Tooltip
                                                            title={value}
                                                            key={column.id}
                                                        >
                                                            <TableCell
                                                                className={
                                                                    classes.ellipsis
                                                                }
                                                                key={column.id}
                                                                align={
                                                                    column.align
                                                                }
                                                                component="a"
                                                                href={`https://mail.google.com/mail/u/${accNumber}/#inbox/${row.thread_id}`}
                                                                target="_blank"
                                                            >
                                                                {value
                                                                    ? value
                                                                    : "(no subject)"}
                                                            </TableCell>
                                                        </Tooltip>
                                                    );
                                                } else if (
                                                    column.id === "created_at"
                                                ) {
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                        >
                                                            {convertDateTime(
                                                                value
                                                            )}
                                                        </TableCell>
                                                    );
                                                } else if (
                                                    column.id === "resolved"
                                                ) {
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                        >
                                                            {row.resolved ? (
                                                                <SuccessButton
                                                                    variant="contained"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        handleResolve(
                                                                            row.id,
                                                                            !row.resolved
                                                                        );
                                                                    }}
                                                                    fullWidth
                                                                >
                                                                    Resolved
                                                                </SuccessButton>
                                                            ) : (
                                                                <Button
                                                                    color="primary"
                                                                    variant="contained"
                                                                    size="small"
                                                                    onClick={() => {
                                                                        handleResolve(
                                                                            row.id,
                                                                            !row.resolved
                                                                        );
                                                                    }}
                                                                    fullWidth
                                                                >
                                                                    Mark
                                                                    Resolved
                                                                </Button>
                                                            )}
                                                        </TableCell>
                                                    );
                                                } else {
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                        >
                                                            {value}
                                                        </TableCell>
                                                    );
                                                }
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={emails.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            {successAlert && (
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={5000}
                    onClose={handleSnackbarClose}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity="success"
                        xs={{ width: "100%" }}
                    >
                        Successfully updated!
                    </Alert>
                </Snackbar>
            )}
            {!successAlert && errors && errors.length > 0 && (
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={5000}
                    onClose={handleSnackbarClose}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity="error"
                        xs={{ width: "100%" }}
                    >
                        {parseErrors(...errors)}
                    </Alert>
                </Snackbar>
            )}
        </div>
    );
}
