import React, {useEffect, useState} from 'react';
import {
    Grid,
    Typography,
    makeStyles,
    FormControl, InputLabel, Select, MenuItem, TextField,
} from "@material-ui/core";
import StartDateRadioGroup from "./StartDateRadioGroup";
import {KeyboardTimePicker} from '@material-ui/pickers';
import {STEPS} from "../WizardStepper";

const useStyles = makeStyles(theme => ({
    box: {
        marginTop: theme.spacing(3),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(2),
        flexGrow: 1,
    },
    selectors: {
        justifyContent: "flex-end",
    },
    text: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    label: {
        color: "#7b7b7b",
    }
}));

export default function HolidayStart(props) {
    const classes = useStyles();
    const holidayName = props.holiday.holiday_name;

    const first = {
        text: props.holiday.assets.first_choice_text,
        value: props.holiday.assets.first_choice_value
    };
    const second = {
        text: props.holiday.assets.second_choice_text,
        value: props.holiday.assets.second_choice_value
    };

    const text = `
		What time should this respond on ${first.text.split(' /')[0]}? The default start time for the Autoresponder.
	  is set at ${first.text.split(' /')[0]} 12pm. Or, you can select a fixed start time or minutes before sundown
	  on ${first.text.split(' /')[0]} or ${second.text.split(' /')[0]}.
	`;

    const setStartDate = () => {
        const date = new Date();
        date.setHours(fixedStartTime[0], fixedStartTime[1]);
        return date;
    };

    const defaultFixedStartTime = props.holidayRulesList[0].fixed_start_time ? props.holidayRulesList[0].fixed_start_time.split(":") : [12, 0];
    const [fixedStartTime, setFixedStartTime] = useState(defaultFixedStartTime); // first item - hours, second - min
    const holidayStartDate = setStartDate();
    const [startOption, setStartOption] = useState(props.holidayRulesList[0].fixed_start_time ? 'fixed' : 'minutes');
    const [daysBefore, setDaysBefore] = useState(props.holidayRulesList[0].days_before);

    const getMinutesBefore = () => {
        let value;
        if (props.holiday.respond_rules.length >= 1 && props.holiday.respond_rules[0].minutes_before) {
            value =  props.holiday.respond_rules[0].minutes_before;
            setStartOption('minutes');
        } else if (props.defaultMinBefore){
            value = props.defaultMinBefore;
        } else (
            value = 0
        );
        return value;
    };
    const [minBeforeSundown, setMinBeforeSundown] = useState(() => getMinutesBefore());


    const handleTimeOptionChange = (event) => {
        setStartOption(event.target.value);
        if (!props.settingsComponent && holidayName === STEPS[1]){
            if (event.target.value === 'fixed') {
                props.roshHashanaRespondRules.fixed_start_time = fixedStartTime.join(':');
                props.roshHashanaRespondRules.minutes_before = null;
            } else {
                props.roshHashanaRespondRules.minutes_before = props.defaultMinBefore;
                props.roshHashanaRespondRules.fixed_start_time = null;
            }
        }
    };

    const handleDaysBeforeChange = (value) => {
        setDaysBefore(value);

        if (!props.settingsComponent && holidayName === STEPS[1]) {
            props.roshHashanaRespondRules.days_before = value;
        }

        if (!props.settingsComponent && holidayName === STEPS[1] && props.roshHashanaRespondRules.fixed_start_time !== null) {
            if ( value === second.value) {
                props.roshHashanaRespondRules.fixed_start_time = "16:00";
            } else if (value === first.value) {
                props.roshHashanaRespondRules.fixed_start_time = "12:00";
            }
        }

        if ( value === second.value) {
            setFixedStartTime([16, 0]);
            if (holidayName === STEPS[5]) {
                setFixedStartTime([12, 0]);
            }
        } else if (value === first.value) {
            if (holidayName === STEPS[5]) {
                setFixedStartTime([16, 0]);
            } else {
                setFixedStartTime([12, 0]);
            }
        }
    };

    const handleMinBeforeSundown = (event) => {
        let minutes = event.target.value;
        let value;
        if (minutes < 0) {
            value = 0;
        } else if (minutes > 120) {
            value = 120;
        } else {
            value = minutes;
        }
        setMinBeforeSundown(value);
        if (!props.settingsComponent && holidayName === STEPS[1]){
            props.setDefaultMinutesBefore(value);
            props.roshHashanaRespondRules.minutes_before = value;
            props.roshHashanaRespondRules.fixed_start_time = null;
        }
    };

    const handleDateChange = (date) => {
        setFixedStartTime([date.getHours(), date.getMinutes()]);
        if (!props.settingsComponent && holidayName === STEPS[1]) {
            props.roshHashanaRespondRules.fixed_start_time = [date.getHours(), date.getMinutes()].join(":");
            props.roshHashanaRespondRules.minutes_before = null;
        }
    };

    const updateDefaultRule = (attrs) => {
        props.setHolidayRules((prevState) => {
            return [{...prevState[0], ...attrs}, ...prevState.slice(1)];
        });
    };

    useEffect(() => {
        holidayStartDate.setHours(fixedStartTime[0], fixedStartTime[1]);
        if(startOption === 'fixed'){
            updateDefaultRule({fixed_start_time: fixedStartTime.join(':'), minutes_before: null});
        } else {
            updateDefaultRule({minutes_before: parseInt(minBeforeSundown), fixed_start_time: null});
        }
    }, [startOption, fixedStartTime, minBeforeSundown]);

    useEffect(() => {
        updateDefaultRule({days_before: daysBefore});
    }, [daysBefore]);

    return (
        <div className={classes.box}>
            <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                {
                    !props.settingsComponent && <Grid item  xs={12} className={classes.text}>
                        {props.daysBefore ? (
                            <Typography>
                                The default start time for the Autoresponder is set to start the day before {holidayName} 5pm.
                                Or, you can select a specific start time.
                            </Typography>
                        ) : (
                            <Typography>{text}</Typography>
                        )}
                    </Grid>
                }
                <Grid item xs={6} style={props.daysBefore && {alignSelf: "center"}}>
                    {props.daysBefore ? (
                        <div>
                            <label htmlFor="start-text" className={classes.label}>Responding start day</label>
                            <Typography name={'start-text'}> Day before {holidayName}</Typography>
                        </div>
                    ): (
                        <StartDateRadioGroup
                            holiday={props.holiday}
                            defaultValue={daysBefore}
                            setValue={handleDaysBeforeChange}
                            first={first}
                            second={second}
                        />
                    )}
                </Grid>
                <Grid item xs={6} sm container spacing={3} className={classes.selectors}>
                    <Grid item xs={5}>
                        <FormControl fullWidth disabled={Boolean(props.daysBefore)}>
                            <InputLabel id="demo-simple-select-label">Start option</InputLabel>
                            <Select
                                value={startOption}
                                label="Start time option"
                                onChange={handleTimeOptionChange}
                            >
                                <MenuItem value={'fixed'}>Fixed time start</MenuItem>
                                <MenuItem value={'minutes'}>Minutes before sundown</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                        {startOption === 'fixed'? (
                            <KeyboardTimePicker
                                label="Fixed start"
                                value={holidayStartDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                        ) : (
                            <TextField
                                required
                                label= "Minutes before sundown"
                                fullWidth
                                type="number"
                                onChange={handleMinBeforeSundown}
                                value={minBeforeSundown}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
