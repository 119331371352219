import React, { useEffect, useState } from "react";
import { useAPI } from "../../common/API";
import {
    Grid,
    CircularProgress,
    Backdrop,
    Modal,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import OfficeTimings from "./OfficeTimings";
import FilterFrom from "./FilterFrom";
import TemplateEditor from "./TemlateEditor";
import EmailRules from "./EmailRules";
import GmailAPIToken from "./GmailAPIToken/GmailAPIToken";
import AutomationSettings from "./AutomationSettings/AutomationSettings";
import { Snackbar } from "@material-ui/core";
import { parseErrors } from "../../utils/ parseErrors";
import { mapValuesToOption } from "./AutomationSettings/utils/mapping";

export default function Settings() {
    const [settings, setSettings] = useState(null),
        api = useAPI();
    const [officeRespStatus, setOfficeRespStatus] = useState(null);
    const [gmailTokenRespStatus, setGmailTokenRespStatus] = useState(null);
    const [templateRespStatus, setTemplateRespStatus] = useState(null);
    const [filterRespStatus, setFilterRespStatus] = useState(null);
    const [emailRespStatus, setEmailRespStatus] = useState(null);
    const [
        automationSettingsRespStatus,
        setAutomationSettingsRespStatus,
    ] = useState(null);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [successAlert, setSuccessAlert] = useState();
    const [errors, setErrors] = useState();
    const [respData, setRespData] = useState();
    const [loading, setLoading] = useState(0);
    const [loadingErrors, setLoadingErrors] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
        setErrors(null);
    };
    const updateLoading = (value) => {
        setLoading(Math.max(0, value));
    };

    const handleResponse = (response, setResponseStatus) => {
        const respStatus = response.status;
        setResponseStatus(respStatus);
        if (respStatus === 200) {
            setSuccessAlert(true);
        } else if (respStatus === 400) {
            setSuccessAlert(false);
        }
        setOpenSnackbar(true);
        return response.json();
    };

    useEffect(() => {
        if (successAlert) {
            setSettings(respData);
        } else {
            if (respData) {
                setErrors(Object.entries(respData));
            }
        }
    }, [respData]);

    useEffect(() => {
        updateLoading(loading + 1);
        api.get("api/v1/settings/")
            .then((res) => {
                if (res.status !== 200) {
                    setLoadingErrors(true);
                }
                return res;
            })
            .then((res) => res.json())
            .then((res) => {
                if (!res.length) {
                    return api
                        .post("api/v1/settings/")
                        .then((res) => res.json());
                }
                return res[0];
            })
            .then((res) => {
                setSettings(res);
                updateLoading(loading - 1);
            })
            .catch((res) => {
                setLoadingErrors(true);
                setLoading(0);
                return res;
            });
    }, []);

    const onOfficeTimingsChange = async (
        office_timing_enabled,
        office_timings,
        timezone
    ) =>
        settings &&
        api
            .put(`api/v1/settings/${settings.id}/`, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ...settings,
                    office_timing_enabled,
                    office_timings,
                    timezone,
                }),
            })
            .then((res) => handleResponse(res, setOfficeRespStatus))
            .then((res) => setRespData(res));

    const onSentFromChange = (sent_from) =>
        settings &&
        api
            .put(`api/v1/settings/${settings.id}/`, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ...settings,
                    sent_from,
                }),
            })
            .then((res) => handleResponse(res, setFilterRespStatus))
            .then((res) => setRespData(res));

    const onTemplateEditorChange = (template_message) =>
        settings &&
        api
            .put(`api/v1/settings/${settings.id}/`, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ...settings,
                    template_message,
                }),
            })
            .then((res) => handleResponse(res, setTemplateRespStatus))
            .then((res) => setRespData(res));

    const onEmailAddressesChange = (emails, email_rules) =>
        settings &&
        api
            .put(`api/v1/settings/${settings.id}/`, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ...settings,
                    emails,
                    email_rules,
                }),
            })
            .then((res) => handleResponse(res, setEmailRespStatus))
            .then((res) => setRespData(res));

    const onGmailAPITokenChange = (user_gmail_auth, gmail_account_number) =>
        settings &&
        api
            .put(`api/v1/settings/${settings.id}/`, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ...settings,
                    user_gmail_auth,
                    gmail_account_number,
                }),
            })
            .then((res) => handleResponse(res, setGmailTokenRespStatus))
            .then((res) => setRespData(res));

    const onAutomationSettingsChange = (payload) => {
        if (!settings) {
            return;
        }
        api.put(`api/v1/settings/${settings.id}/`, {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...settings,
                ...payload,
            }),
        })
            .then((res) => handleResponse(res, setAutomationSettingsRespStatus))
            .then((res) => setRespData(res));
    };

    const useStyles = makeStyles((theme) => ({
        paper: {
            position: "absolute",
            width: 500,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: theme.spacing(4),
            outline: "none",
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing.unit / 2,
            top: theme.spacing.unit / 2,
            color: theme.palette.grey[500],
        },
    }));

    function getModalStyle() {
        return {
            top: "20%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        };
    }
    const [modalStyle] = useState(getModalStyle);
    const classes = useStyles();
    return (
        <>
            <Backdrop open={loading} style={{ zIndex: 1 }}>
                <CircularProgress color="inherit" size={60} />
            </Backdrop>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={loadingErrors}
                onClose={null}
            >
                <div style={modalStyle} className={classes.paper}>
                    <div style={{ textAlign: "center" }}>
                        <Typography variant="h6" id="modal-title">
                            Server Error
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="simple-modal-description"
                        >
                            Try refresh page or contact our support team{" "}
                            <a href="mailto:support@ezcloudllc.com">
                                support@ezcloudllc.com
                            </a>
                        </Typography>
                    </div>
                </div>
            </Modal>
            <Grid
                container
                spacing={3}
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Grid item xs={12} md={8} lg={9}>
                    <>
                        <GmailAPIToken
                            onChange={onGmailAPITokenChange}
                            userAuth={
                                settings ? settings.user_gmail_auth : false
                            }
                            oauthUrl={settings ? settings.gmail_oauth_url : ""}
                            accountNumber={
                                settings ? settings.gmail_account_number : 0
                            }
                            respStatus={gmailTokenRespStatus}
                            setRespStatus={setGmailTokenRespStatus}
                            setOpenSnackbar={setOpenSnackbar}
                        />
                        <AutomationSettings
                            replySetting={
                                settings
                                    ? mapValuesToOption({
                                          autoReply: settings.reply_on_emails,
                                          officeTimingReply:
                                              settings.office_timing_enabled,
                                          holidaysReply:
                                              settings.holidays_enabled,
                                          replyAlways: settings.reply_always,
                                      })
                                    : 5
                            }
                            onChange={onAutomationSettingsChange}
                            respStatus={automationSettingsRespStatus}
                            setRespStatus={setAutomationSettingsRespStatus}
                            setOpenSnackbar={setOpenSnackbar}
                        ></AutomationSettings>
                        <OfficeTimings
                            enabled={
                                settings
                                    ? settings.office_timing_enabled
                                    : false
                            }
                            values={settings ? settings.office_timings : []}
                            timezone={settings ? settings.timezone : "EST"}
                            onChange={onOfficeTimingsChange}
                            respStatus={officeRespStatus}
                            setRespStatus={setOfficeRespStatus}
                            setOpenSnackbar={setOpenSnackbar}
                            holidaysOnly={
                                settings ? settings.holidays_only : true
                            }
                        />
                        <TemplateEditor
                            templateMessage={
                                settings
                                    ? settings.template_message
                                    : "some text"
                            }
                            onChange={onTemplateEditorChange}
                            respStatus={templateRespStatus}
                            setRespStatus={setTemplateRespStatus}
                            setOpenSnackbar={setOpenSnackbar}
                        />
                        <FilterFrom
                            sentFrom={settings ? settings.sent_from : []}
                            onChange={onSentFromChange}
                            respStatus={filterRespStatus}
                            setRespStatus={setFilterRespStatus}
                            setOpenSnackbar={setOpenSnackbar}
                        />
                        <EmailRules
                            emails={settings ? settings.emails : []}
                            emailRules={settings ? settings.email_rules : []}
                            onChange={onEmailAddressesChange}
                            respStatus={emailRespStatus}
                            setRespStatus={setEmailRespStatus}
                            setOpenSnackbar={setOpenSnackbar}
                        />
                    </>
                    {successAlert && (
                        <Snackbar
                            open={openSnackbar}
                            autoHideDuration={5000}
                            onClose={handleClose}
                        >
                            <Alert
                                onClose={handleClose}
                                severity="success"
                                xs={{ width: "100%" }}
                            >
                                Settings successfully updated!
                            </Alert>
                        </Snackbar>
                    )}
                    {!successAlert && errors && errors.length > 0 && (
                        <Snackbar
                            open={openSnackbar}
                            autoHideDuration={5000}
                            onClose={handleClose}
                        >
                            <Alert
                                onClose={handleClose}
                                severity="error"
                                xs={{ width: "100%" }}
                            >
                                {parseErrors(...errors)}
                            </Alert>
                        </Snackbar>
                    )}
                </Grid>
            </Grid>
        </>
    );
}
