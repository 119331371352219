import React, { useState } from 'react';
import {
    Button,
    Grid,
    Switch,
    Toolbar
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


export default function NewEmailRule(props) {
  const [rule, setEmailRule] = useState({
    enabled: false,
    email: null,
    reply_from: null,
  });
  const setValue = value => setEmailRule({...rule, ...value});
  return (
    <Toolbar style={{padding: '15px'}}>
      <Grid container direction="row"
            justify="space-between" spacing={3} alignItems="center">
        <Grid item>
            <Switch checked={rule.enabled}
                    onChange={e => setValue({enabled: e.target.checked})}
                    color="primary" />
        </Grid>
        <Grid item xs={4} lg={4}>
          <Autocomplete
            freeSolo
            id="email"
            title={"All emails which come to this email, will be processed by this rule."}
            disableClearable
            fullWidth={true}
            options={props.emails.map((option) => option.email)}
             onChange={(event,
                        newValue) => {
              setValue({email: newValue});
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Email inbox"
                margin="normal"
                InputProps={{ ...params.InputProps, type: 'search' }}
                onChange={e => setValue({email: e.target.value})}
              />
            )}
          />
        </Grid>
        <Grid item xs={4} lg={4}>
          <Autocomplete
            freeSolo
            id="reply_from"
            title={"All emails matching email inbox address will be replied using this email address."}
            disableClearable
            fullWidth={true}
            options={props.emails.map((option) => option.email)}
            onChange={(event,
                       newValue) => {
              setValue({reply_from: newValue});
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Reply from"
                margin="normal"
                InputProps={{ ...params.InputProps, type: 'search' }}
                onChange={e => setValue({reply_from: e.target.value})}
              />
            )}
          />
        </Grid>
        <Grid item>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => {
                if (rule.email && rule.reply_from) {
                    props.addEmailRule(rule);
                }

                }
            }
            >Add</Button>
        </Grid>
      </Grid>
    </Toolbar>
  );
}
