import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { useAPI } from '../common/API';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';



const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit / 2,
    top: theme.spacing.unit / 2,
    color: theme.palette.grey[500],
  },
}));

function getModalStyle() {
  return {
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
}


const DialogModal = () => {
  const api = useAPI();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState();
  const classes = useStyles();

  useEffect(() => {
    api.get('api/v1/auth/user/')
      .then(res => res.json())
      .then(data => {
        data && data.enable && !data.is_expired ? setOpen(false) : setOpen(true);
      });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return(
    <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
          <div style={modalStyle} className={classes.paper}>
            <div style={{textAlign: "center",}}>
              <Typography variant="h6" id="modal-title">
                The system is not enabled
              </Typography>
              <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
                  <CloseIcon />
              </IconButton>
              <Typography variant="subtitle1" id="simple-modal-description">
                Please contact our support team <a
                href="mailto:support@ezcloudllc.com">support@ezcloudllc.com</a>
              </Typography>
            </div>
          </div>

      </Modal>
  );
};

export default DialogModal;