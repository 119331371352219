import { Grid, makeStyles, Paper, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useAPI } from "../../../common/API";
import DeterminateLinearProgress from "../../../utils/DeterminateLinearProgress";

const useEmailRuleStyles = makeStyles((theme) => ({
    disabled: {
        backgroundColor: theme.palette.action.hover,
    },
    root: {
        transition: theme.transitions.create("background-color", {
            duration: "300ms",
        }),
        marginBottom: 15,
    },
    row: {
        paddingLeft: 15,
        paddingRight: 15,
        marginBottom: 10,
        direction: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    toolbar: {
        paddingLeft: 15,
        paddingRight: 15,
    },
}));

export default function GmailAPIToken(props) {
    const userAuth = props.userAuth;
    const api = useAPI();
    const classes = useEmailRuleStyles();
    const [accNumber, setAccNumber] = useState(props.accountNumber);
    const respStatus = props.respStatus;
    const [sendingReq, setSendingReq] = useState(false);

    const update = (token, accNum) => {
        props.setOpenSnackbar(false);
        setSendingReq(true);
        return props.onChange(token, accNum);
    };

    useEffect(() => {
        if (respStatus) {
            setSendingReq(false);
            props.setRespStatus(null);
        }
    }, [respStatus]);

    useEffect(() => {
        setAccNumber(props.accountNumber);
    }, [props.accountNumber]);

    const deleteOAuthToken = () => {
        setSendingReq(true);
        api.patch(`api/v1/auth/user/`, {
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                google_oauth_json: "",
            }),
        })
            .then(props.onChange(""))
            .then((res) => {
                if (res) {
                    props.setRespStatus(res.status);
                    if (res.ok) {
                        return res.json();
                    }
                }
            });
    };
    const onAddClick = () => update(props.userAuth, accNumber);

    return (
        <Paper className={classes.root}>
            <DeterminateLinearProgress visible={sendingReq} opened={true} />
            <Toolbar className={classes.toolbar}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    component="label"
                >
                    <Typography variant="h6" component="div">
                        Gmail Settings
                    </Typography>
                </Grid>
            </Toolbar>
            <Grid
                container
                direction="row"
                spacing={3}
                justify="space-around"
                className={classes.row}
            >
                <Grid
                    container
                    direction="row"
                    spacing={3}
                    justify="space-around"
                    className={classes.row}
                >
                    <Grid item xs={6}>
                        <Typography>API Token</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "end" }}>
                        {userAuth ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={deleteOAuthToken}
                            >
                                Delete Token
                            </Button>
                        ) : (
                            <Button
                                label="API Token"
                                variant="contained"
                                color="primary"
                                href={props.oauthUrl}
                                onClick={onAddClick}
                            >
                                Get Token
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "end" }}></Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}
