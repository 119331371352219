import {
    Collapse,
    Grid,
    makeStyles,
    Paper,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Button,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import ArrowDropUpOutlinedIcon from "@material-ui/icons/ArrowDropUpOutlined";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import DeterminateLinearProgress from "../../../utils/DeterminateLinearProgress";
import { getPayloadFromOption } from "./utils/mapping";

const replyOptions = [
    {
        label: "Reply Always",
        value: 1,
    },
    {
        label: "Reply on Response Time Hour Only",
        value: 2,
    },
    {
        label: "Reply on Response Time Hour and Holidays Only",
        value: 3,
    },
    {
        label: "Reply on Holidays Only",
        value: 4,
    },
    {
        label: "Don't Reply",
        value: 5,
    },
];

const useAutomationSettingsStyles = makeStyles((theme) => ({
    disabled: {
        backgroundColor: theme.palette.action.hover,
    },
    root: {
        transition: theme.transitions.create("background-color", {
            duration: "300ms",
        }),
        marginBottom: 15,
    },
    header: {
        cursor: "pointer",
    },
    bottomAddPanel: {
        width: "auto",
    },
    editor: {
        padding: 5,
        margin: 0,
    },
    saveButton: {
        padding: 5,
        margin: 0,
    },
    enableSwitch: {
        marginBottom: 15,
        marginLeft: 15,
        marginRight: 15,
    },
    toolbar: {
        paddingLeft: 15,
        paddingRight: 15,
    },
    row: {
        marginBottom: theme.spacing(2),
        direction: "row",
        justifyContent: "space-between",
        alignItems: "left",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    element: {
        marginBottom: theme.spacing(3),
    },
}));

export default function AutomationSettings(props) {
    const classes = useAutomationSettingsStyles();
    const [openSettings, setOpenSettings] = useState(false);
    const respStatus = props.respStatus;
    const [sendingReq, setSendingReq] = useState(false);
    const [replySetting, setReplySetting] = useState(props.replySetting);

    const handleOpen = () => {
        setOpenSettings(!openSettings);
    };
    const updateReplySetting = (newValue) => {
        setReplySetting(newValue);
        const payload = getPayloadFromOption(newValue);
        props.setOpenSnackbar(false);
        setSendingReq(true);
        props.onChange(payload);
    };
    const handleHelpClick = () => {
        window.open(
            "https://docs.google.com/document/d/1bwVIuOBsXi9twkLUrhqMOJ3nXtG7qF3ZBmy7_-Xr4Do",
            "_blank"
        );
    };
    useEffect(() => {
        if (respStatus) {
            setSendingReq(false);
            props.setRespStatus(null);
        }
    }, [respStatus]);

    useEffect(() => {
        setReplySetting(props.replySetting);
    }, [props.replySetting]);

    return (
        <Paper
            className={clsx(classes.root, !openSettings && classes.disabled)}
        >
            <DeterminateLinearProgress
                visible={sendingReq}
                opened={openSettings}
            />
            <Toolbar className={classes.toolbar} onClick={handleOpen}>
                <Grid
                    className={classes.header}
                    container
                    direction="row"
                    justifyContent="space-between"
                    component="label"
                >
                    <Grid item>
                        <Typography variant="h6" component="div">
                            Automation Settings
                        </Typography>
                    </Grid>
                    <Grid item>
                        {!openSettings ? (
                            <ArrowDropDownOutlinedIcon
                                fontVariant="outlined"
                                color="primary"
                                fontSize="large"
                                onClick={handleOpen}
                            />
                        ) : (
                            <ArrowDropUpOutlinedIcon
                                fontVariant="outlined"
                                color="primary"
                                fontSize="large"
                                onClick={handleOpen}
                            />
                        )}
                    </Grid>
                </Grid>
            </Toolbar>
            <Collapse in={openSettings}>
                <Grid
                    className={classes.row}
                    container
                    direction="row"
                    justifyContent="space-between"
                    component="label"
                >
                    <Grid item xs={12} sm={6} className={classes.element}>
                        <FormControl fullWidth size="medium">
                            <InputLabel>Auto Reply</InputLabel>
                            <Select
                                required
                                value={replySetting}
                                onChange={(e) =>
                                    updateReplySetting(e.target.value)
                                }
                            >
                                {replyOptions.map((option) => (
                                    <MenuItem
                                        value={option.value}
                                        key={option.value}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.element}
                        style={{ textAlign: "end", alignItems: "flex-end" }}
                    >
                        <Button
                            onClick={handleHelpClick}
                            color="primary"
                            variant="contained"
                        >
                            <Typography>Help</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Collapse>
        </Paper>
    );
}
