import {makeStyles} from "@material-ui/core/styles";
import {Box, LinearProgress} from "@material-ui/core";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {grey} from "@material-ui/core/colors";

const SlowLinearProgress = withStyles({
  bar: {
    animationDuration: "8s"
  },
})(LinearProgress);

const useStyles = makeStyles({
    linearProgress: {
        backgroundColor: "white",
        animationDuration: "20s",
    },
});

export default function DeterminateLinearProgress (props) {
    const classes = useStyles();
    const bgColor = props.opened ? 'white' : grey[500];
    return(
       <Box sx={{width: '100%'}}>
           {props.visible ? (
               <SlowLinearProgress className={classes.linearProgress}/>
           ) : (
            <LinearProgress
                color={bgColor}
                variant="determinate" value={0}
            />
           )}
      </Box>
    );
}