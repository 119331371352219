import React, { useEffect } from 'react';
import GoogleLogin, { GoogleLogout } from 'react-google-login';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import { GOOGLE_CLIENT_ID, GOOGLE_SCOPES } from 'common/variables';
import { AuthContext, useAuth, useProvideAuth } from 'common/auth';


export function ProvideAuth({ children }) {
  const token = localStorage.getItem('hijackToken');
  const user = localStorage.getItem('hijackUserObj');
  const parsedToken = token !== 'undefined' ? JSON.parse(token) : null;
  const parsedUser = token !== 'undefined' ? JSON.parse(user) : null;
  const auth = useProvideAuth({token: parsedToken, user: parsedUser});

  useEffect(() => {
    if (localStorage.getItem('hijackUser') !== 'true'){
      auth.performCheck();
    }
    if (localStorage.getItem('hijackToken') === 'undefined') {
      localStorage.setItem('hijackUser', 'false');
    }
  }, []);

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}


export function LoginButton() {
  const history = useHistory(),
    location = useLocation(),
    auth = useAuth();

  const { from } = location.state || { from: { pathname: '/' } };
  const onSuccess = resp => auth.signIn(resp).then(() => history.replace(from));
  const onFailure = resp => console.error(resp);

  return <GoogleLogin
    clientId={GOOGLE_CLIENT_ID}
    buttonText="Sign In"
    onSuccess={onSuccess}
    onFailure={onFailure}
    // uxMode="redirect"
    cookiePolicy={'single_host_origin'}
    scope={GOOGLE_SCOPES.join(' ')}
  />;
}


export function LogoutButton(props) {
  const auth = useAuth(),
    history = useHistory();

  const logout = resp => auth.signOut(resp).then(() => history.push('/'));
  const onFailure = resp => console.error(resp);

  return <GoogleLogout
    clientId={GOOGLE_CLIENT_ID}
    className={props.className}
    buttonText="Logout"
    onLogoutSuccess={logout}
    onFailure={onFailure}
    //uxMode="redirect"
    scope={GOOGLE_SCOPES.join(' ')}
  />;
}


export function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={({location}) => auth.user ? (children) : (
        <Redirect to={{
          pathname: '/sign-in',
          state: { from: location },
        }}/>
      )}
    />
  );
}

export function CalendarPrivateRoute({ children, ...rest }) {
  const auth = useAuth();
  const user = auth.user;
  return (
    <Route
      {...rest}
      render={({location}) => user && user.enable_hebcal_calendar ? (children) : (
        <Redirect to={{
          pathname: '/',
          state: { from: location },
        }}/>
      )}
    />
  );
}
